import React from "react"
import useDebounceQueryParams from "magik-react-hooks/useRouterDebounceQueryParams"
import Column from "../Column/Column"
import {
  Card,
  CardContent,
  CardHeader,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core"
import { Delete, Search } from "@material-ui/icons"
import { Pagination } from "@material-ui/lab"
import { useHistory } from "react-router-dom"
import { usePermissions } from "../../state/permissions"

export default function ListClienti({
  clienti,
  onUpdate,
  onDelete,
  columnProps,
  pagination,
}) {
  const [setQueryParams, debQueryParams] = useDebounceQueryParams()
  const history = useHistory()
  const permissions = usePermissions()

  return (
    <Card>
      <CardHeader title="Clienti" className={"card-header-no-border"} />
      <CardContent className={"card-no-padding"}>
        <TableContainer component={Paper}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <Column {...columnProps} field="rag_sociale">
                  Ragione Sociale
                </Column>
                <Column {...columnProps} field="piva">
                  Partita Iva
                </Column>
                <Column {...columnProps} field="cod_fatturazione">
                  Cod. Fatturazione
                </Column>
                <TableCell>Azioni</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {clienti &&
                clienti.map((cliente) => (
                  <TableRow key={cliente.id}>
                    <TableCell>{cliente.rag_sociale}</TableCell>
                    <TableCell>{cliente.piva}</TableCell>
                    <TableCell>{cliente.cod_fatturazione}</TableCell>

                    <TableCell>
                      <div className={"icons-list"}>
                        <Search
                          onClick={() => history.push(`/clienti/${cliente.id}`)}
                          className="pointer"
                          fontSize="small"
                        />
                        {/* <Edit
                          onClick={() => onUpdate(cliente)}
                          className="pointer"
                          fontSize="small"
                        /> */}
                        {permissions.canWriteClienti && (
                          <Delete
                            onClick={() => onDelete(cliente)}
                            className="pointer"
                            fontSize="small"
                          />
                        )}
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </CardContent>
      <Pagination
        className="pagination"
        count={pagination.numPages}
        color="primary"
        page={+debQueryParams.page || 1}
        onChange={(e, page) => setQueryParams({ page })}
      />
    </Card>
  )
}
