import { useAuthUser } from "use-eazy-auth"

export function getUserPermission(user) {
  const isEcommerceAdmin = user.ecommerce_admin != null

  return {
    canViewOrdini: true,
    canWriteOrdini: isEcommerceAdmin,
    canViewCategorie: isEcommerceAdmin,
    canWriteCategorie: isEcommerceAdmin,
    canViewClienti: true,
    canWriteClienti: isEcommerceAdmin,
    canViewProdotti: isEcommerceAdmin,
    canWriteProdotti: isEcommerceAdmin,
    canViewMagazzino: isEcommerceAdmin,
    canWriteMagazzino: isEcommerceAdmin,
    canViewVideo: true,
    canWriteVideo: isEcommerceAdmin,
  }
}

export function usePermissions() {
  const { user } = useAuthUser()
  return getUserPermission(user)
}
