import {
  Button,
  Card,
  CardContent,
  CardHeader,
  DialogActions,
  FormControlLabel,
  Switch,
} from "@material-ui/core"
import { useAuthActions, useAuthUser } from "use-eazy-auth"
import Layout from "../../components/Layout/Layout"
import CheckIcon from "@material-ui/icons/Check"
import HighlightOffIcon from "@material-ui/icons/HighlightOff"
import { Link } from "react-router-dom"
import { useRj } from "react-rocketjump"
import { ProfileAdminState } from "../../state/profile"

export default function Profile() {
  const { user } = useAuthUser()
  const { updateUser } = useAuthActions()
  const [, { updateProfile }] = useRj(ProfileAdminState)

  return (
    <Layout>
      <Card>
        <CardHeader
          title={<div className="title-card-big">Profilo {user.username}</div>}
        />
        <CardContent>
          <div>
            {user.first_name}
            {user.last_name}
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <div style={{ marginRight: 10 }}>Admin? </div>
            {user.ecommerce_admin ? (
              <CheckIcon color="primary" />
            ) : (
              <HighlightOffIcon color="error" />
            )}
          </div>
          <div>
            <FormControlLabel
              control={
                <Switch
                  checked={user.ecommerce_admin.receive_order_email}
                  onChange={() => {
                    updateProfile
                      .onSuccess((data) => {
                        updateUser({
                          ...user,
                          ecommerce_admin: {
                            ...user.ecommerce_admin,
                            receive_order_email: data.receive_order_email,
                          },
                        })
                      })
                      .run({ receive_order_email: !user.ecommerce_admin.receive_order_email })
                  }}
                  name={"receive_order_email"}
                />
              }
              label={"Notifiche e-mail attive"}
            />
          </div>
        </CardContent>
        <DialogActions style={{ display: "flex", justifyContent: "flex-end" }}>
          <Button component={Link} to="/change-password" variant="outlined">
            Cambia Password
          </Button>
        </DialogActions>
      </Card>
    </Layout>
  )
}
