import * as Sentry from '@sentry/react'
import EazyAuth, { useAuthActions } from 'use-eazy-auth'
import { ConfigureRj } from 'react-rocketjump'
import api from './api'

const loginCall = (params) =>
  api
    .mapResponse((r) => ({
      accessToken: r.response.access,
      refreshToken: r.response.refresh,
    }))
    .post('api/commerce/token/', params)
const meCall = (token) => api.auth(token).get('/api/commerce/me')

const refreshTokenCall = (refreshToken) =>
  api
    .mapResponse((r) => ({
      accessToken: r.response.access,
      refreshToken: r.response.refresh || refreshToken,
    }))
    .post('/api/commerce/token/refresh/', { refresh: refreshToken })

function InnerAuth({ children }) {
  const { callAuthApiObservable } = useAuthActions()
  return (
    <ConfigureRj effectCaller={callAuthApiObservable}>{children}</ConfigureRj>
  )
}

function Auth({ children }) {
  return (
    <EazyAuth
      onAuthenticate={user => {
        Sentry.setUser({
          id: user.id,
          username: user.username,
          email: user.email,
        })
      }}
      onLogout={() => {
        Sentry.setUser(null)
      }}
      loginCall={loginCall}
      meCall={meCall}
      refreshTokenCall={refreshTokenCall}
    >
      <InnerAuth>{children}</InnerAuth>
    </EazyAuth>
  )
}

export default Auth
