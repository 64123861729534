import React from "react"
import * as Yup from "yup"
import { Field, Formik } from "formik"
import {
  ImageField,
  InputField,
  setNullOnEmptyValues,
  SwitchCheckboxField,
  transformErrorForForm,
} from "../Form"
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Button,
  TextField,
} from "@material-ui/core"
import { KeyboardBackspace } from "@material-ui/icons"
import ButtonSave from "../ButtonSave"
import { Autocomplete } from "@material-ui/lab"
import { Transition } from "../../consts"

const INITIAL_VALUES = {
  name: "",
  parent: "",
  image: "",
  active: true,
}

const ProdottoSchema = Yup.object().shape({
  name: Yup.string().required("Nome è obbligatorio"),
})

export default function ModalFormCategoria({
  categoria,
  save,
  isOpen,
  toggle,
  onExited,
  categories,
}) {
  const parentInitial =
    categories && categoria && categoria.parent
      ? categories.filter((c) => c.id === categoria.parent)[0]
      : ""

  return (
    <Dialog open={isOpen} onClose={toggle} TransitionComponent={Transition}>
      <DialogTitle onClose={toggle}>
        {categoria ? "Modifica" : "Inserimento"} categoria
      </DialogTitle>
      <Formik
        validationSchema={ProdottoSchema}
        initialValues={
          categoria
            ? {
                ...categoria,
                name: categoria.name,
                parent: parentInitial,
                id: categoria.id,
              }
            : INITIAL_VALUES
        }
        onSubmit={(values, actions) => {
          save(setNullOnEmptyValues(values)).catch((err) => {
            actions.setErrors(transformErrorForForm(err))
          })
        }}
      >
        {({ handleSubmit, isSubmitting, values, setFieldValue }) => {
          return (
            <form onSubmit={handleSubmit}>
              <DialogContent dividers>
                <Grid container spacing={3}>
                  <Grid item md={12} xs={12}>
                    <Field label={"Nome"} name="name" component={InputField} />
                  </Grid>
                  <Grid item md={12} xs={12}>
                    <Autocomplete
                      noOptionsText={"Nessuna categoria trovato"}
                      value={values.parent}
                      id="size-small-standard-multi"
                      size="medium"
                      name="parent"
                      onChange={(e, value) => setFieldValue("parent", value)}
                      getOptionSelected={(option, value) =>
                        option.parent === value.id
                      }
                      options={categories}
                      getOptionLabel={(option) => option.name}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          label="Categoria"
                          placeholder="Seleziona Categoria"
                        />
                      )}
                    />
                  </Grid>
                  <Grid item md={12} sm={12}>
                    <Field
                      label={"Immagine"}
                      variant="outlined"
                      name="image"
                      component={ImageField}
                    />
                  </Grid>
                  <Grid item md={12} xs={12}>
                    <Field
                      label="Attivo?"
                      component={SwitchCheckboxField}
                      name="active"
                    />
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button
                  startIcon={<KeyboardBackspace />}
                  variant="outlined"
                  onClick={toggle}
                >
                  Chiudi
                </Button>
                <ButtonSave
                  disabled={isSubmitting}
                  type="submit"
                  variant="outlined"
                >
                  {categoria ? "Modifica" : "Aggiungi"}
                </ButtonSave>
              </DialogActions>
            </form>
          )
        }}
      </Formik>
    </Dialog>
  )
}
