import React from "react"
import * as Yup from "yup"
import { Field, Formik } from "formik"
import {
  DateField,
  fixInitialValuesNull,
  InputField,
  setNullOnEmptyValues,
  transformErrorForForm,
} from "../Form"
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Button,
  TextField,
  MenuItem,
} from "@material-ui/core"
import { KeyboardBackspace } from "@material-ui/icons"
import ButtonSave from "../ButtonSave"
import { Autocomplete } from "@material-ui/lab"
import { STATES_ORDER, Transition } from "../../consts"

const INITIAL_VALUES = {
  customer: "",
  state: "bozza",
  rag_sociale: "",
  piva: "",
  cod_fatturazione: "",
  address: "",
  data_ricezione: "",
  data_spedizione: "",
}

export default function ModalFormOrder({
  ordine,
  save,
  isOpen,
  clienti,
  toggle,
  onExited,
}) {
  const ProdottoSchema = Yup.object().shape({
    customer: ordine
      ? undefined
      : Yup.object().nullable().required("Il cliente è obbligatorio"),
    state: ordine ? Yup.string().required("Stato è obbligatorio") : undefined,
  })
  return (
    <Dialog
      fullWidth={true}
      maxWidth={"sm"}
      open={isOpen}
      onClose={toggle}
      TransitionComponent={Transition}
    >
      <DialogTitle onClose={toggle}>
        {ordine ? "Modifica" : "Inserimento"} ordine
      </DialogTitle>
      <Formik
        validationSchema={ProdottoSchema}
        initialValues={
          ordine
            ? fixInitialValuesNull({
                ...ordine,
                customer:
                  clienti && clienti.filter((d) => d.id === ordine.customer)[0],
              })
            : INITIAL_VALUES
        }
        onSubmit={(values, actions) =>
          save(setNullOnEmptyValues(values)).catch((err) => {
            actions.setErrors(transformErrorForForm(err))
          })
        }
      >
        {({
          handleSubmit,
          isSubmitting,
          setFieldValue,
          values,
          errors,
          touched,
        }) => (
          <form onSubmit={handleSubmit}>
            <DialogContent dividers>
              <Grid container spacing={3}>
                {!ordine && (
                  <Grid item md={12}>
                    <Autocomplete
                      onChange={(e, value) => {
                        if (value.rag_sociale) {
                          setFieldValue("rag_sociale", value.rag_sociale)
                          setFieldValue("piva", value.piva)
                          setFieldValue(
                            "cod_fatturazione",
                            value.cod_fatturazione
                          )
                          setFieldValue("customer", value)
                        }
                      }}
                      getOptionLabel={(option) =>
                        option ? option.rag_sociale : ""
                      }
                      id="combo-box-demo"
                      options={clienti}
                      getOptionSelected={(option, value) =>
                        option.id === value.id
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          error={!!errors["customer"]}
                          helperText={
                            !!errors["customer"] || touched["customer"]
                              ? errors["customer"]
                              : undefined
                          }
                          label="Cliente"
                          name="customer"
                        />
                      )}
                    />
                  </Grid>
                )}
                {ordine && (
                  <Grid item md={12} xs={12}>
                    <Field
                      label={"Stato"}
                      name="state"
                      component={InputField}
                      select
                      defaultValue="bozza"
                      value={values.state}
                    >
                      {Object.keys(STATES_ORDER).map((state, i) => (
                        <MenuItem value={state} key={i}>
                          {STATES_ORDER[state]}
                        </MenuItem>
                      ))}
                    </Field>
                  </Grid>
                )}
                {values.state === "ricevuto" && (
                  <Grid item md={12} xs={12}>
                    <Field
                      label={"Data ricezione"}
                      name="data_ricezione"
                      fullWidth
                      component={DateField}
                    />
                  </Grid>
                )}
                {values.state === "spedito" && (
                  <Grid item md={12} xs={12}>
                    <Field
                      label={"Data spedizione"}
                      name="data_spedizione"
                      fullWidth
                      component={DateField}
                    />
                  </Grid>
                )}
                <Grid item md={12}>
                  Indirizzo di spedizione
                </Grid>

                <Grid item md={12} xs={12}>
                  <Field
                    label={"Indirizzi registrati"}
                    name="address"
                    disabled={
                      !values ||
                      values.customer === "" ||
                      values.customer.addresses_data.length === 0
                    }
                    component={InputField}
                    onChange={(e) => {
                      setFieldValue("address", e.target.value.id)
                      setFieldValue("citta", e.target.value.citta)
                      setFieldValue("indirizzo", e.target.value.indirizzo)
                      setFieldValue("cap", e.target.value.cap)
                      setFieldValue("provincia", e.target.value.provincia)
                    }}
                    select
                  >
                    {values.customer.addresses_data &&
                      values.customer.addresses_data.map((address, i) => (
                        <MenuItem value={address}>
                          {address.indirizzo}, {address.citta} - {address.cap}
                        </MenuItem>
                      ))}
                  </Field>
                </Grid>

                <Grid item md={12}>
                  <Field
                    name="indirizzo"
                    InputLabelProps={{ shrink: true }}
                    label="Indirizzo"
                    component={InputField}
                  />
                </Grid>
                <Grid item md={6}>
                  <Field
                    name="citta"
                    InputLabelProps={{ shrink: true }}
                    label="Città"
                    component={InputField}
                  />
                </Grid>
                <Grid item md={4}>
                  <Field
                    name="cap"
                    InputLabelProps={{ shrink: true }}
                    label="Cap"
                    component={InputField}
                  />
                </Grid>
                <Grid item md={2}>
                  <Field
                    name="provincia"
                    InputLabelProps={{ shrink: true }}
                    label="Provincia"
                    component={InputField}
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button
                startIcon={<KeyboardBackspace />}
                variant="outlined"
                onClick={toggle}
              >
                Chiudi
              </Button>
              <ButtonSave
                disabled={isSubmitting}
                type="submit"
                variant="outlined"
              >
                {ordine ? "Modifica" : "Aggiungi"}
              </ButtonSave>
            </DialogActions>
          </form>
        )}
      </Formik>
    </Dialog>
  )
}
