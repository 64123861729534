import { rj } from "react-rocketjump"
import rjPlainList from "react-rocketjump/plugins/plainList"
import api from "../api"

export const CategoriaState = rj(rjPlainList(), {
  mutations: {
    removeCategoria: {
      effect: (t) => (entity) =>
        api
          .auth(t)
          .mapResponse(() => entity)
          .delete(`/api/commerce/categories/${entity.id}`),
      updater: "deleteItem",
      // Uncomment to make delete optimistic
      // optimistiResult: entity => entity,
    },
    addCategoria: {
      effect: (t) => (entity) => {
        const fd = new FormData()
        fd.append("name", entity.name)
        fd.append("active", entity.active)
        if (entity.parent) {
          fd.append("parent", entity.parent)
        }
        if (entity.image && entity.image instanceof File) {
          fd.append("image", entity.image)
        }
        return api.auth(t).post("/api/commerce/categories", fd)
      },
      updater: "insertItem",
    },
    updateCategoria: {
      effect: (t) => (entity) => {
        console.log(entity)
        const fd = new FormData()
        fd.append("name", entity.name)
        fd.append("active", entity.active)
        if (entity.parent) {
          fd.append("parent", entity.parent)
        }
        if (entity.image && entity.image instanceof File) {
          fd.append("image", entity.image)
        }
        return api.auth(t).put(`/api/commerce/categories/${entity.id}/`, fd)
      },
      updater: "updateItem",
    },
  },
  name: "CategoriaState",
  effectCaller: "configured",
  effect:
    (t) =>
    (params = {}) =>
      api.auth(t).get("/api/commerce/categories", params),
  computed: {
    list: "getList",
    loading: "isLoading",
  },
})
