import { rj } from "react-rocketjump"
import rjPlainList from "react-rocketjump/plugins/plainList"
import rjList, {
  nextPreviousPaginationAdapter,
} from "react-rocketjump/plugins/list"
import api from "../api"
import { PAGE_SIZE } from "../consts"

export const ClienteState = rj(
  rjList({
    pagination: nextPreviousPaginationAdapter,
    pageSize: PAGE_SIZE,
  }),
  {
    name: "ClienteState",
    effectCaller: "configured",
    mutations: {
      addCustomer: {
        effect: (t) => (entity) =>
          api.auth(t).post("/api/commerce/customers", entity),
        updater: "insertItem",
      },
      updateCustomer: {
        effect: (t) => (entity) =>
          api.auth(t).put(`/api/commerce/customers/${entity.id}/`, entity),
        updater: "updateItem",
      },
      removeCustomer: {
        effect: (t) => (entity) =>
          api
            .auth(t)
            .mapResponse(() => entity)
            .delete(`/api/commerce/customers/${entity.id}`),
        updater: "deleteItem",
        // Uncomment to make delete optimistic
        // optimistiResult: entity => entity,
      },
    },
    effect:
      (t) =>
      (params = {}) =>
        api.auth(t).get("/api/commerce/customers", params),
    computed: {
      pagination: "getPagination",
      list: "getList",
      loading: "isLoading",
    },
  }
)

export const ClientiAutocompleteState = rj(

    rjPlainList(),
    {
      name: "ClientiAutocompleteState",
      effectCaller: "configured",
      effect: (t) => (params = {}) => api.auth(t).get("/api/commerce/customers-autocomplete", params),
      computed: {
        list: "getList",
        loading: "isLoading",
      },
    }
)

export const ClienteDetailState = rj({
  name: "Detail",
  effectCaller: "configured",
  mutations: {
    updateOrder: {
      effect: (t) => (entity) =>
        api.auth(t).put(`/api/commerce/customers/${entity.id}/`, entity),
      updater: "updateData",
    },
  },
  effect: (t) => (id) => api.auth(t).get(`/api/commerce/customers/${id}`),
})

export const ClienteAddressState = rj(

  rjPlainList(),
  {
    mutations: {
      removeAddress: {
        effect: (t) => (entity) =>
          api
            .auth(t)
            .mapResponse(() => entity)
            .delete(`/api/commerce/customers-addresses/${entity.id}`),
        updater: "deleteItem",
        // Uncomment to make delete optimistic
        // optimistiResult: entity => entity,
      },
      addAddress: {
        effect: (t) => (entity) => api.auth(t).post("/api/commerce/customers-addresses", entity),
        updater: "insertItem",
      },
      updateAddress: {
        effect: (t) => (entity) =>
          api.auth(t).put(`/api/commerce/customers-addresses/${entity.id}/`, entity),
        updater: "updateItem",
      },
    },
    name: "ClienteAddressState",
    effectCaller: "configured",
    effect: (t) => (params = {}) => api.auth(t).get("/api/commerce/customers-addresses", params),
    computed: {
      list: "getList",
      loading: "isLoading",
    },
  }
)