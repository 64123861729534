import {
  Card,
  CardContent,
  CardHeader,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from "@material-ui/core"
import { Add, Delete, Edit } from "@material-ui/icons"
import { usePermissions } from "../../state/permissions"

export default function CardRigheOrdine({
  righe,
  actionsModalAddOrderRow,
  actionsModalUpdateOrderRow,
  actionsModalDeleteOrderRow,
}) {
  const permissions = usePermissions()
  return (
    <Card>
      <CardHeader
        title={
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div className="title-card-big">Righe ordine</div>
            {permissions.canWriteOrdini && (
              <Add
                color={"primary"}
                className={"pointer"}
                onClick={() => actionsModalAddOrderRow.open()}
              />
            )}
          </div>
        }
      />
      <CardContent>
        {righe && righe.length > 0 ? (
          <Table size="small">
            <TableBody>
              <TableRow>
                <TableCell className={"bg-varianti"}>Prodotto</TableCell>
                <TableCell className={"bg-varianti"}>Codice</TableCell>
                <TableCell className={"bg-varianti"}>Prezzo singolo</TableCell>
                <TableCell className={"bg-varianti"}>Quantità</TableCell>
                <TableCell className={"bg-varianti"}>Prezzo totale</TableCell>
                <TableCell className={"bg-varianti"}></TableCell>
              </TableRow>
              {righe.map((riga, i) => (
                <TableRow key={i}>
                  <TableCell
                    className={
                      i % 2 === 0 ? "bg-varianti-scuro" : "bg-varianti-chiaro"
                    }
                  >
                    {riga.product_title}
                  </TableCell>
                  <TableCell
                    className={
                      i % 2 === 0 ? "bg-varianti-scuro" : "bg-varianti-chiaro"
                    }
                  >
                    {riga.code}
                  </TableCell>
                  <TableCell
                    className={
                      i % 2 === 0 ? "bg-varianti-scuro" : "bg-varianti-chiaro"
                    }
                  >
                    {riga.single_price} &euro;
                  </TableCell>
                  <TableCell
                    className={
                      i % 2 === 0 ? "bg-varianti-scuro" : "bg-varianti-chiaro"
                    }
                  >
                    {riga.quantity}
                  </TableCell>
                  <TableCell
                    className={
                      i % 2 === 0 ? "bg-varianti-scuro" : "bg-varianti-chiaro"
                    }
                  >
                    {riga.total_price} &euro;
                  </TableCell>
                  <TableCell>
                    <div className={"icons-list"}>
                      {/* <Edit
                        onClick={() => actionsModalUpdateOrderRow.open(riga)}
                        className="pointer"
                        fontSize="small"
                      /> */}
                      {permissions.canWriteOrdini && (
                        <Delete
                          onClick={() => actionsModalDeleteOrderRow.open(riga)}
                          className="pointer"
                          fontSize="small"
                        />
                      )}
                    </div>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        ) : (
          <p>Non ci sono righe per questo ordine.</p>
        )}
      </CardContent>
    </Card>
  )
}
