import React from 'react'
import { useAuthActions } from 'use-eazy-auth'
import { Formik, Field } from 'formik'
import * as Yup from 'yup'
import { transformErrorForForm, InputField } from '../../components/Form'
import Layout from '../../components/Layout/Layout'
import {
  Button,
  Card,
  CardHeader,
  CardContent,
  DialogActions,
} from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { Link } from 'react-router-dom'
import ButtonSave from '../../components/ButtonSave'
import { changePasswordApi } from '../../state/password'

const ChangePasswordSchema = Yup.object().shape({
  password: Yup.string()
    .required('Password è un campo obbligatorio')
    .min(8, 'La password deve essere lunga almeno 8 caratteri.'),
  repeatPassword: Yup.string()
    .required('Conferma Password è un campo obbligatorio')
    .oneOf([Yup.ref('password'), null], 'Le password non corrispondono.'),
})

export default function ChangePassword() {
  const { callAuthApiPromise } = useAuthActions()
  return (
    <Layout>
      <Formik
        initialValues={{
          password: '',
          repeatPassword: '',
        }}
        validationSchema={ChangePasswordSchema}
        onSubmit={(values, actions) => {
          actions.setStatus(false)
          return callAuthApiPromise(changePasswordApi, values.password).then(
            () => {
              actions.setStatus(true)
            },
            (error) => {
              console.log(error)
              actions.setErrors(transformErrorForForm(error))
            }
          )
        }}
      >
        {({ handleSubmit, isSubmitting, errors, submitCount, status }) => (
          <form onSubmit={handleSubmit}>
            <Card>
              <CardHeader
                title={<div className="title-card-big">Cambia Password</div>}
              />
              <CardContent>
                <Field
                  margin="normal"
                  label="Password"
                  type="password"
                  name="password"
                  component={InputField}
                />
                <Field
                  margin="normal"
                  label="Conferma Password"
                  type="password"
                  name="repeatPassword"
                  component={InputField}
                />
                {status && (
                  <Alert color="success">
                    Password modificata con successo.
                  </Alert>
                )}
              </CardContent>
              <DialogActions
                style={{ display: 'flex', justifyContent: 'space-between' }}
              >
                <Button component={Link} to="/profile" variant="outlined">
                  Profilo
                </Button>
                <ButtonSave
                  disabled={isSubmitting}
                  type="submit"
                  variant="outlined"
                >
                  Cambia Password
                </ButtonSave>
              </DialogActions>
            </Card>
          </form>
        )}
      </Formik>
    </Layout>
  )
}
