import { rj } from "react-rocketjump"
import qs from 'query-string'
import rjPlainList from "react-rocketjump/plugins/plainList"
import rjList, {
  nextPreviousPaginationAdapter,
} from "react-rocketjump/plugins/list"
import api from "../api"
import { PAGE_SIZE } from "../consts"

export const ProdottoState = rj(
  rjList({
    pagination: nextPreviousPaginationAdapter,
    pageSize: PAGE_SIZE,
  }),
  {
    mutations: {
      removeProdotto: {
        effect: (t) => (entity) =>
          api
            .auth(t)
            .mapResponse(() => entity)
            .delete(`/api/commerce/products/${entity.id}`),
        updater: "deleteItem",
        // Uncomment to make delete optimistic
        // optimistiResult: entity => entity,
      },
      addProdotto: {
        effect: (t) => (entity) => {
          const fd = new FormData()
          fd.append("title", entity.title)
          fd.append("active", entity.active)
          fd.append("description", entity.description)
          fd.append("categories", entity.categories)
          if (entity.image && entity.image instanceof File) {
            fd.append("image", entity.image)
          }
          return api.auth(t).post("/api/commerce/products", fd)
        },
        updater: "insertItem",
      },
      updateProdotto: {
        effect: (t) => (entity) => {
          const fd = new FormData()
          fd.append("title", entity.title)
          fd.append("active", entity.active)
          fd.append("description", entity.description)
          fd.append("categories", entity.categories)
          if (entity.image && entity.image instanceof File) {
            fd.append("image", entity.image)
          }
          return api.auth(t).put(`/api/commerce/products/${entity.id}/`, fd)
        },
        updater: "updateItem",
      },
    },
    name: "ProdottoState",
    effectCaller: "configured",
    effect:
      (t) =>
      (params = {}) =>
        api.auth(t).get(`/api/commerce/products?${qs.stringify(params)}`),
    computed: {
      pagination: "getPagination",
      list: "getList",
      loading: "isLoading",
    },
  }
)

export const ProdottoDetailState = rj({
  name: "Detail",
  effectCaller: "configured",
  mutations: {
    updateProdotto: {
      effect: (t) => (entity) => {
        const fd = new FormData()
        fd.append("title", entity.title)
        fd.append("active", entity.active)
        fd.append("description", entity.description)
        fd.append("categories", entity.categories)
        if (entity.image && entity.image instanceof File) {
          fd.append("image", entity.image)
        }
        return api.auth(t).put(`/api/commerce/products/${entity.id}/`, fd)
      },
      updater: "updateData",
    },
  },
  effect: (t) => (id) => api.auth(t).get(`/api/commerce/products/${id}`),
})

export const ProductFeatureState = rj(rjPlainList(), {
  mutations: {
    removeFeature: {
      effect: (t) => (entity) =>
        api
          .auth(t)
          .mapResponse(() => entity)
          .delete(`/api/commerce/products-features/${entity.id}`),
      updater: "deleteItem",
      // Uncomment to make delete optimistic
      // optimistiResult: entity => entity,
    },
    addFeature: {
      effect: (t) => (entity) =>
        api.auth(t).post("/api/commerce/products-features", entity),
      updater: "insertItem",
    },
    updateFeature: {
      effect: (t) => (entity) =>
        api
          .auth(t)
          .put(`/api/commerce/products-features/${entity.id}/`, entity),
      updater: "updateItem",
    },
    sortFeatures: {
      optimisticResult: (idProdotto, type, features) => features,
      optimisticUpdater: "updateData",
      effect: (t) => (idProdotto, type, features) =>
        api.auth(t).put(`/api/commerce/products/${idProdotto}/order-features`, {
          feature_type: type,
          features_ids: features.map((f) => f.id),
        }),
    },
  },
  name: "ProductFeatureState",
  effectCaller: "configured",
  effect:
    (t) =>
    (params = {}) =>
      api.auth(t).get("/api/commerce/products-features", params),
  computed: {
    list: "getList",
    loading: "isLoading",
  },
})

export const ProductAttributeState = rj(rjPlainList(), {
  mutations: {
    removeAttribute: {
      effect: (t) => (entity) =>
        api
          .auth(t)
          .mapResponse(() => entity)
          .delete(`/api/commerce/products-attributes/${entity.id}`),
      updater: "deleteItem",
      // Uncomment to make delete optimistic
      // optimistiResult: entity => entity,
    },
    addAttribute: {
      effect: (t) => (entity) =>
        api.auth(t).post("/api/commerce/products-attributes", entity),
      updater: "insertItem",
    },
    updateAttribute: {
      effect: (t) => (entity) =>
        api
          .auth(t)
          .put(`/api/commerce/products-attributes/${entity.id}/`, entity),
      updater: "updateItem",
    },
    sortAttributes: {
      optimisticResult: (idProdotto, attributes) => attributes,
      optimisticUpdater: "updateData",
      effect: (t) => (idProdotto, attributes) =>
        api
          .auth(t)
          .put(`/api/commerce/products/${idProdotto}/order-attributes`, {
            attributes_ids: attributes.map((f) => f.id),
          }),
    },
  },
  name: "ProductAttributeState",
  effectCaller: "configured",
  effect:
    (t) =>
    (params = {}) =>
      api.auth(t).get("/api/commerce/products-attributes", params),
  computed: {
    list: "getList",
    loading: "isLoading",
  },
})

export const ProductAttributeVariantState = rj(rjPlainList(), {
  mutations: {
    removeAttributeVariant: {
      effect: (t) => (entity) =>
        api
          .auth(t)
          .mapResponse(() => entity)
          .delete(`/api/commerce/products-attributes-variant/${entity.id}`),
      updater: "deleteItem",
      // Uncomment to make delete optimistic
      // optimistiResult: entity => entity,
    },
    addAttributeVariant: {
      effect: (t) => (entity) =>
        api.auth(t).post("/api/commerce/products-attributes-variant", entity),
      updater: "insertItem",
    },
    updateAttributeVariant: {
      effect: (t) => (entity) =>
        api
          .auth(t)
          .put(
            `/api/commerce/products-attributes-variant/${entity.id}/`,
            entity
          ),
      updater: "updateItem",
    },
    sortAttributesVariant: {
      optimisticResult: (idProdotto, attributes) => attributes,
      optimisticUpdater: "updateData",
      effect: (t) => (idProdotto, attributes) =>
        api
          .auth(t)
          .put(
            `/api/commerce/products/${idProdotto}/order-attributes-variant`,
            {
              attributes_variant_ids: attributes.map((f) => f.id),
            }
          ),
    },
  },
  name: "ProductAttributeVariantState",
  effectCaller: "configured",
  effect:
    (t) =>
    (params = {}) =>
      api.auth(t).get("/api/commerce/products-attributes-variant", params),
  computed: {
    list: "getList",
    loading: "isLoading",
  },
})

export const ProductVariantState = rj(rjPlainList(), {
  mutations: {
    removeVariant: {
      effect: (t) => (entity) =>
        api
          .auth(t)
          .mapResponse(() => entity)
          .delete(`/api/commerce/products-variant/${entity.id}`),
      updater: "deleteItem",
      // Uncomment to make delete optimistic
      // optimistiResult: entity => entity,
    },
    addVariant: {
      effect: (t) => (entity) =>
        api.auth(t).post("/api/commerce/products-variant", entity),
      updater: "insertItem",
    },
    updateVariant: {
      effect: (t) => (entity) =>
        api.auth(t).put(`/api/commerce/products-variant/${entity.id}/`, entity),
      updater: "updateItem",
    },
    // sortAttributesVariant: {
    //   optimisticResult: (idProdotto, attributes) => attributes,
    //   optimisticUpdater: "updateData",
    //   effect: (t) => (idProdotto, attributes) => api.auth(t).put(`/api/commerce/products/${idProdotto}/order-attributes-variant`,{
    //     attributes_variant_ids: attributes.map(f => f.id)
    //   })
    // }
  },
  name: "ProductVariantState",
  effectCaller: "configured",
  effect:
    (t) =>
    (params = {}) =>
      api.auth(t).get("/api/commerce/products-variant", params),
  computed: {
    list: "getList",
    loading: "isLoading",
  },
})

export const ProductComboState = rj(rjPlainList(), {
  mutations: {
    removeCombo: {
      effect: (t) => (entity) =>
        api
          .auth(t)
          .mapResponse(() => entity)
          .delete(`/api/commerce/products-combo/${entity.id}`),
      updater: "deleteItem",
      // Uncomment to make delete optimistic
      // optimistiResult: entity => entity,
    },
    addCombo: {
      effect: (t) => (entity) =>
        api.auth(t).post("/api/commerce/products-combo", entity),
      updater: "insertItem",
    },
    updateCombo: {
      effect: (t) => (entity) =>
        api.auth(t).put(`/api/commerce/products-combo/${entity.id}/`, entity),
      updater: "updateItem",
    },
    updateMagazzino: {
      effect: (t) => (entity) =>
        api
          .auth(t)
          .put(`/api/commerce/products-warehouse/${entity.id}/`, entity),
      updater: "updateItem",
    },
    sortCombo: {
      optimisticResult: (idProdotto, variants) => variants,
      optimisticUpdater: "updateData",
      effect: (t) => (idProdotto, variants) =>
        api.auth(t).put(`/api/commerce/products/${idProdotto}/order-variants`, {
          variants_ids: variants.map((f) => f.id),
        }),
    },
  },
  name: "ProductComboState",
  effectCaller: "configured",
  effect:
    (t) =>
    (params = {}) =>
      api.auth(t).get("/api/commerce/products-combo", params),
  computed: {
    list: "getList",
    loading: "isLoading",
  },
})

export const ProductMagazzinoState = rj(
  rjList({
    pagination: nextPreviousPaginationAdapter,
    pageSize: PAGE_SIZE,
  }),
  {
    mutations: {
      updateQuantity: {
        effect: (t) => (entity) =>
          api
            .auth(t)
            .put(`/api/commerce/products-warehouse/${entity.id}/`, entity),
        updater: "updateItem",
      },
    },
    name: "ProductMagazzinoState",
    effectCaller: "configured",
    effect:
      (t) =>
      (params = {}) =>
        api.auth(t).get("/api/commerce/products-warehouse", params),
    computed: {
      pagination: "getPagination",
      list: "getList",
      loading: "isLoading",
    },
  }
)

export const ProdottiAutocompleteState = rj(

  rjPlainList(),
  {
    name: "ProdottiAutocompleteState",
    effectCaller: "configured",
    effect: (t) => (params = {}) => api.auth(t).get("/api/commerce/products-autocomplete", params),
    computed: {
      list: "getList",
      loading: "isLoading",
    },
  }
)
