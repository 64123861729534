import React from "react"
import {
  Card,
  CardContent,
  CardHeader,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core"
import { Check, Clear, Delete, Edit } from "@material-ui/icons"

export default function ListCategorie({ categorie, onUpdate, onDelete }) {
  const categoriesSorted =
    categorie &&
    categorie.reduce((accumulator, currentValue) => {
      let item = accumulator.find((x) => x.id === currentValue.parent)
      let index = accumulator.indexOf(item)
      index = index !== -1 ? index + 1 : accumulator.length
      accumulator.splice(index, 0, currentValue)
      return accumulator
    }, [])

  return (
    <Card>
      <CardHeader title="Categorie" className={"card-header-no-border"} />
      <CardContent className={"card-no-padding"}>
        <TableContainer component={Paper}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Macrocategoria</TableCell>
                <TableCell>Categoria</TableCell>
                <TableCell>Immagine</TableCell>
                <TableCell>Attiva?</TableCell>
                <TableCell>Azioni</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {categoriesSorted &&
                categoriesSorted.map((cat, i) => (
                  <TableRow key={cat.id}>
                    <TableCell
                      className={cat.parent ? "" : "cell-header-category"}
                    >
                      {cat.parent ? cat.parent_name : cat.name}
                    </TableCell>
                    <TableCell
                      className={cat.parent ? "" : "cell-header-category"}
                    >
                      {cat.parent ? cat.name : ""}
                    </TableCell>
                    <TableCell
                      className={cat.parent ? "" : "cell-header-category"}
                    >
                      {cat.image && (
                        <img src={cat.image} alt={cat.name} height={50} />
                      )}
                    </TableCell>
                    <TableCell
                      className={cat.parent ? "" : "cell-header-category"}
                    >
                      {cat.active ? (
                        <Check color="primary" />
                      ) : (
                        <Clear color="secondary" />
                      )}
                    </TableCell>
                    <TableCell
                      className={cat.parent ? "" : "cell-header-category"}
                      style={{ whiteSpace: "nowrap" }}
                    >
                      <div className={"icons-list"}>
                        <Edit
                          onClick={() => onUpdate(cat)}
                          className="pointer"
                          fontSize="small"
                        />
                        <Delete
                          onClick={() => onDelete(cat)}
                          className="pointer"
                          fontSize="small"
                        />
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </CardContent>
    </Card>
  )
}
