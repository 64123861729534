import { CircularProgress, Grid } from "@material-ui/core"
import { useRunRj } from "react-rocketjump"
import useModalTrigger from "magik-react-hooks/useModalTrigger"
import { useParams } from "react-router"
import { OrderDetailState, OrderRowState } from "../../state/orders"
import Layout from "../../components/Layout/Layout"
import CardDetailOrder from "../../components/Ordini/CardDetailOrder"
import CardRigheOrdine from "../../components/Ordini/CardRigheOrdine"
import ModalUpdateStato from "../../components/Ordini/ModalUpdateStato"
import CardLogState from "../../components/Ordini/CardLogsState"
import ModalFormOrderRow from "../../components/Ordini/ModalFormOrderRow"
import { useMemo } from "react"
import {
  ProdottiAutocompleteState,
  ProductComboState,
} from "../../state/prodotti"
import ModalDelete from "../../components/Modals/ModalDelete"
import ModalFormOrder from "../../components/Ordini/ModalFormOrder"
import { ClientiAutocompleteState } from "../../state/clienti"

export default function DettaglioOrdine() {
  const { id } = useParams()
  const filtersRow = useMemo(() => {
    return { order: id }
  }, [id])
  const [{ data: ordine }, { updateOrder, run }] = useRunRj(OrderDetailState, [
    id,
  ])
  const [{ list: rows }, { addRow, updateRow, removeRow }] = useRunRj(
    OrderRowState,
    [filtersRow]
  )

  const [{ list: clienti }] = useRunRj(ClientiAutocompleteState)
  const [{ list: prodotti }] = useRunRj(ProdottiAutocompleteState)
  const [{ list: combos }] = useRunRj(ProductComboState)
  const [modalUpdateStato, actionsModalUpdateStato] = useModalTrigger()
  const [modalUpdateOrder, actionsModalUpdateOrder] = useModalTrigger()
  const [modalAddOrderRow, actionsModalAddOrderRow] = useModalTrigger()
  const [modalUpdateOrderRow, actionsModalUpdateOrderRow] = useModalTrigger()
  const [modalDeleteOrderRow, actionsModalDeleteOrderRow] = useModalTrigger()
  const { value: deleteOrdineRow } = modalDeleteOrderRow


  return (
    <Layout title={"Dettaglio Ordine"}>
      {!ordine ||
        (!rows && (
          <div style={{ textAlign: "center" }}>
            <CircularProgress color="primary" />
          </div>
        ))}
      {ordine && rows && (
        <Grid container spacing={4}>
          <Grid md={12} item>
            <CardDetailOrder
              ordine={ordine}
              actionsModalUpdateStato={actionsModalUpdateStato}
              actionsModalUpdateOrder={actionsModalUpdateOrder}
            />
          </Grid>
          <Grid item md={12}>
            <CardRigheOrdine
              actionsModalAddOrderRow={actionsModalAddOrderRow}
              actionsModalUpdateOrderRow={actionsModalUpdateOrderRow}
              actionsModalDeleteOrderRow={actionsModalDeleteOrderRow}
              righe={rows}
            />
          </Grid>
          <Grid item md={6}>
            <CardLogState logs={ordine.order_logs_data} />
          </Grid>
        </Grid>
      )}

      <ModalFormOrder
        save={(ordine) => {
          return updateOrder
            .onSuccess((order) => {
              actionsModalUpdateOrder.close()
            })
            .asPromise({
              ...ordine,
              state: ordine.state,
              cap: ordine.cap,
              citta: ordine.citta,
              data_spedizione: ordine.data_spedizione,
              data_ricezione: ordine.data_ricezione,
              provincia: ordine.provincia,
              indirizzo: ordine.indirizzo,
              id: ordine.id,
              customer: ordine.customer.id,
            })
        }}
        isOpen={modalUpdateOrder.isOpen}
        clienti={clienti}
        ordine={ordine}
        toggle={actionsModalUpdateOrder.toggle}
        onExited={actionsModalUpdateOrder.onClosed}
      />

      <ModalUpdateStato
        isOpen={modalUpdateStato.isOpen}
        toggle={actionsModalUpdateStato.toggle}
        ordine={ordine ?? {}}
        save={(order) => {
          return updateOrder
            .onSuccess(() => actionsModalUpdateStato.close())
            .asPromise(order)
        }}
      />

      <ModalFormOrderRow
        isOpen={modalAddOrderRow.isOpen}
        toggle={actionsModalAddOrderRow.toggle}
        combos={combos ?? []}
        prodotti={prodotti ?? []}
        save={(row) => {
          return addRow
            .onSuccess(() => {
              actionsModalAddOrderRow.close()
              run(id)
            })
            .asPromise({
              ...row,
              product_title: row.product.label,
              product_combo: row.product_combo.value,
              code: row.product_combo.label,
              order: id,
            })
        }}
      />
      <ModalFormOrderRow
        isOpen={modalUpdateOrderRow.isOpen}
        toggle={actionsModalUpdateOrderRow.toggle}
        combos={combos ?? []}
        row={modalUpdateOrderRow.value}
        prodotti={prodotti ?? []}
        save={(row) => {
          return updateRow
            .onSuccess(() => {
              actionsModalAddOrderRow.close()
            })
            .asPromise({
              ...row,
              product_title: row.product.label,
              product_combo: row.product_combo.value,
              code: row.product_combo.label,
              order: id,
            })
        }}
      />
      <ModalDelete
        isOpen={modalDeleteOrderRow.isOpen}
        toggle={actionsModalDeleteOrderRow.toggle}
        title={
          deleteOrdineRow
            ? `Rimuovere la riga ordine per la combinazione ${deleteOrdineRow.code}?`
            : null
        }
        text={
          deleteOrdineRow
            ? `Sei sicuro di voler eliminare la riga ordine per la combinazione ${deleteOrdineRow.code}?`
            : null
        }
        onDelete={() => {
          return removeRow
            .onSuccess(() => {
              actionsModalDeleteOrderRow.close()
              run(id)
            })
            .asPromise(deleteOrdineRow)
        }}
        onExited={actionsModalDeleteOrderRow.onClosed}
      />
    </Layout>
  )
}
