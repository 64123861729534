import { Grid } from "@material-ui/core"
import useModalTrigger from "magik-react-hooks/useModalTrigger"
import { useRunRj } from "react-rocketjump"
import { useParams } from "react-router-dom"
import Layout from "../../components/Layout/Layout"
import ModalFormProdotto from "../../components/Prodotti/ModalFormProdotto"
import { CategoriaState } from "../../state/categorie"
import {
  ProdottoDetailState,
  ProductAttributeState,
  ProductAttributeVariantState,
  ProductComboState,
  // ProductFeatureState,
} from "../../state/prodotti"
import { useMemo } from "react"
import { maxBy } from "lodash-es"
// import ModalFormFeature from "../../components/Prodotti/ModalFormFeature"
import ModalDelete from "../../components/Modals/ModalDelete"
import ModalFormAttribute from "../../components/Prodotti/ModalFormAttribute"
import ModalFormAttributeVariant from "../../components/Prodotti/ModalFormAttributeVariant"
import ModalFormVariant from "../../components/Prodotti/ModalFormVariant"
// import { DATI_TECNICI_KEY, VANTAGGI_KEY } from "../../consts"
import CardAttributes from "../../components/Prodotti/CardAttributes"
import CardDetailProduct from "../../components/Prodotti/CardDetailProduct"
import CardAttributesVariant from "../../components/Prodotti/CardAttributesVariant"
import CardVariants from "../../components/Prodotti/CardVariants"
// import CardDatiTecnici from "../../components/Prodotti/CardDatiTecnici"
// import CardVantaggi from "../../components/Prodotti/CardVantaggi"
import ModalMagazzino from "../../components/Prodotti/ModalMagazzino"

export default function ProdottoDettaglio() {
  const { id } = useParams()
  const [{ data: prodotto }, { updateProdotto }] = useRunRj(
    ProdottoDetailState,
    [id]
  )

  const [{ list: categories }] = useRunRj(CategoriaState)

  // const filtersDatiTecnici = useMemo(() => {
  //   return { product: id, feature_type: DATI_TECNICI_KEY }
  // }, [id])

  // const filtersVantaggi = useMemo(() => {
  //   return { product: id, feature_type: VANTAGGI_KEY }
  // }, [id])

  const filtersAttributes = useMemo(() => {
    return { product: id }
  }, [id])

  // const [
  //   { list: featuresTecniche },
  //   {
  //     sortFeatures: sortFeaturesTecniche,
  //     addFeature: addDatoTecnico,
  //     removeFeature: removeDatoTecnico,
  //     updateFeature: updateDatoTecnico,
  //   },
  // ] = useRunRj(ProductFeatureState, [filtersDatiTecnici])

  // const [
  //   { list: featuresVantaggi },
  //   {
  //     sortFeatures: sortFeaturesVantaggi,
  //     addFeature: addVantaggio,
  //     updateFeature: updateVantaggio,
  //     removeFeature: removeVantaggio,
  //   },
  // ] = useRunRj(ProductFeatureState, [filtersVantaggi])

  const [
    { list: attributes },
    { addAttribute, removeAttribute, updateAttribute, sortAttributes },
  ] = useRunRj(ProductAttributeState, [filtersAttributes])

  const [
    { list: attributesVariant },
    {
      addAttributeVariant,
      removeAttributeVariant,
      updateAttributeVariant,
      sortAttributesVariant,
    },
  ] = useRunRj(ProductAttributeVariantState, [filtersAttributes])

  const [{ list: combos }, { addCombo, removeCombo, updateCombo, sortCombo }] =
    useRunRj(ProductComboState, [filtersAttributes])

  // const nextOrderFeatureTecnicaToUse = useMemo(() => {
  //   if (!featuresTecniche || featuresTecniche.length === 0) {
  //     return 0
  //   }
  //   return maxBy(featuresTecniche, "order").order + 1
  // }, [featuresTecniche])

  // const nextOrderFeatureVantaggioToUse = useMemo(() => {
  //   if (!featuresVantaggi || featuresVantaggi.length === 0) {
  //     return 0
  //   }
  //   return maxBy(featuresVantaggi, "order").order + 1
  // }, [featuresVantaggi])

  const nextOrderAttributeToUse = useMemo(() => {
    if (!attributes || attributes.length === 0) {
      return 0
    }
    return maxBy(attributes, "order").order + 1
  }, [attributes])

  const nextOrderAttributeVariantToUse = useMemo(() => {
    if (!attributesVariant || attributesVariant.length === 0) {
      return 0
    }
    return maxBy(attributesVariant, "order").order + 1
  }, [attributesVariant])

  const nextOrderVariantToUse = useMemo(() => {
    if (!combos || combos.length === 0) {
      return 0
    }
    return maxBy(combos, "order").order + 1
  }, [combos])

  const [modalUpdateProdotto, actionsModalUpdateProdotto] = useModalTrigger()
  // const [modalAddDatoTecnico, actionsModalAddDatoTecnico] = useModalTrigger()
  // const [modalUpdateDatoTecnico, actionsModalUpdateDatoTecnico] =
  //   useModalTrigger()
  // const [modalDeleteDatoTecnico, actionsModalDeleteDatoTecnico] =
  //   useModalTrigger()
  // const [modalAddVantaggio, actionsModalAddVantaggio] = useModalTrigger()
  // const [modalUpdateVantaggio, actionsModalUpdateVantaggio] = useModalTrigger()
  // const [modalDeleteVantaggio, actionsModalDeleteVantaggio] = useModalTrigger()
  const [modalAddAttribute, actionsModalAddAttribute] = useModalTrigger()
  const [modalUpdateAttribute, actionsModalUpdateAttribute] = useModalTrigger()
  const [modalDeleteeAttribute, actionsModalDeleteAttribute] = useModalTrigger()
  const [modalAddAttributeVariant, actionsModalAddAttributeVariant] =
    useModalTrigger()
  const [modalUpdateAttributeVariant, actionsModalUpdateAttributeVariant] =
    useModalTrigger()
  const [modalDeleteAttributeVariant, actionsModalDeleteAttributeVariant] =
    useModalTrigger()
  const [modalAddVariant, actionsModalAddVariant] = useModalTrigger()
  const [modalUpdateVariant, actionsModalUpdateVariant] = useModalTrigger()
  const [modalDeleteVariant, actionsModalDeleteVariant] = useModalTrigger()

  const [modalMagazzino, actionsModalMagazzino] = useModalTrigger()

  return (
    <Layout title={"Dettaglio prodotto"}>
      {prodotto && (
        <Grid container spacing={4}>
          <Grid md={12} item>
            <CardDetailProduct
              prodotto={prodotto}
              actionsModalUpdateProdotto={actionsModalUpdateProdotto}
            />
          </Grid>
          <Grid md={6} item>
            <CardAttributesVariant
              actionsModalAddAttributeVariant={actionsModalAddAttributeVariant}
              actionsModalUpdateAttributeVariant={
                actionsModalUpdateAttributeVariant
              }
              actionsModalDeleteAttributeVariant={
                actionsModalDeleteAttributeVariant
              }
              productId={id}
              sortAttributesVariant={sortAttributesVariant}
              attributesVariant={attributesVariant}
            />
          </Grid>
          <Grid item md={6}>
            <CardAttributes
              actionsModalAddAttribute={actionsModalAddAttribute}
              actionsModalUpdateAttribute={actionsModalUpdateAttribute}
              actionsModalDeleteAttribute={actionsModalDeleteAttribute}
              productId={id}
              sortAttributes={sortAttributes}
              attributes={attributes}
            />
          </Grid>
          <Grid md={12} item>
            <CardVariants
              combos={combos}
              actionsModalAddVariant={actionsModalAddVariant}
              actionsModalUpdateVariant={actionsModalUpdateVariant}
              actionsModalDeleteVariant={actionsModalDeleteVariant}
              attributesVariant={attributesVariant}
              sortCombo={sortCombo}
              productId={id}
              actionsModalMagazzino={actionsModalMagazzino}
            />
          </Grid>
          {/* <Grid item md={6}>
            <CardDatiTecnici
              productId={id}
              actionsModalAddDatoTecnico={actionsModalAddDatoTecnico}
              actionsModalUpdateDatoTecnico={actionsModalUpdateDatoTecnico}
              actionsModalDeleteDatoTecnico={actionsModalDeleteDatoTecnico}
              features={featuresTecniche}
              sortFeaturesTecniche={sortFeaturesTecniche}
            />
          </Grid>
          <Grid item md={6}>
            <CardVantaggi
              productId={id}
              actionsModalAddVantaggio={actionsModalAddVantaggio}
              actionsModalUpdateVantaggio={actionsModalUpdateVantaggio}
              actionsModalDeleteVantaggio={actionsModalDeleteVantaggio}
              features={featuresVantaggi}
              sortFeaturesVantaggi={sortFeaturesVantaggi}
            />
          </Grid> */}
        </Grid>
      )}
      <ModalFormProdotto
        save={(prodotto) => {
          return updateProdotto
            .onSuccess(() => actionsModalUpdateProdotto.close())
            .asPromise({
              ...prodotto,
              categories: prodotto.categories.map((cat) => cat.id),
            })
        }}
        prodotto={modalUpdateProdotto.value}
        isOpen={modalUpdateProdotto.isOpen}
        categories={categories}
        toggle={actionsModalUpdateProdotto.toggle}
      />
      <ModalFormAttribute
        save={(attributes) => {
          return addAttribute
            .onSuccess(() => actionsModalAddAttribute.close())
            .asPromise({
              product: id,
              order: nextOrderAttributeToUse,
              ...attributes,
            })
        }}
        prodotto={modalAddAttribute.value}
        isOpen={modalAddAttribute.isOpen}
        toggle={actionsModalAddAttribute.toggle}
      />
      <ModalFormAttribute
        save={(attributes) => {
          return updateAttribute
            .onSuccess(() => actionsModalUpdateAttribute.close())
            .asPromise({
              ...attributes,
            })
        }}
        attribute={modalUpdateAttribute.value}
        isOpen={modalUpdateAttribute.isOpen}
        toggle={actionsModalUpdateAttribute.toggle}
      />
      <ModalDelete
        isOpen={modalDeleteeAttribute.isOpen}
        toggle={actionsModalDeleteAttribute.toggle}
        title={modalDeleteeAttribute.value ? `Rimuovere l'attributo?` : null}
        text={
          modalDeleteeAttribute.value
            ? `Sei sicuro di voler eliminare l'attributo ${modalDeleteeAttribute.value.name}?`
            : null
        }
        onDelete={() => {
          return removeAttribute
            .onSuccess(() => actionsModalDeleteAttribute.close())
            .asPromise(modalDeleteeAttribute.value)
        }}
      />
      <ModalFormAttributeVariant
        save={(attributes) => {
          return addAttributeVariant
            .onSuccess(() => actionsModalAddAttributeVariant.close())
            .asPromise({
              product: id,
              order: nextOrderAttributeVariantToUse,
              ...attributes,
            })
        }}
        isOpen={modalAddAttributeVariant.isOpen}
        toggle={actionsModalAddAttributeVariant.toggle}
      />
      <ModalFormAttributeVariant
        save={(attributes) => {
          return updateAttributeVariant
            .onSuccess(() => actionsModalUpdateAttributeVariant.close())
            .asPromise({
              ...attributes,
            })
        }}
        attribute={modalUpdateAttributeVariant.value}
        isOpen={modalUpdateAttributeVariant.isOpen}
        toggle={actionsModalUpdateAttributeVariant.toggle}
      />
      <ModalDelete
        isOpen={modalDeleteAttributeVariant.isOpen}
        toggle={actionsModalDeleteAttribute.toggle}
        title={
          modalDeleteAttributeVariant.value
            ? `Rimuovere l'attributo variante?`
            : null
        }
        text={
          modalDeleteAttributeVariant.value
            ? `Sei sicuro di voler eliminare l'attributo ${modalDeleteAttributeVariant.value.name}?`
            : null
        }
        onDelete={() => {
          return removeAttributeVariant
            .onSuccess(() => actionsModalDeleteAttributeVariant.close())
            .asPromise(modalDeleteAttributeVariant.value)
        }}
      />
      {/* <ModalFormFeature
        save={(feature) => {
          return addDatoTecnico
            .onSuccess(() => actionsModalAddDatoTecnico.close())
            .asPromise({
              order: nextOrderFeatureTecnicaToUse,
              product: id,
              feature_type: DATI_TECNICI_KEY,
              ...feature,
            })
        }}
        type={"dato tecnico"}
        isOpen={modalAddDatoTecnico.isOpen}
        toggle={actionsModalAddDatoTecnico.toggle}
      />
      <ModalFormFeature
        save={(feature) => {
          return updateDatoTecnico
            .onSuccess(() => actionsModalUpdateDatoTecnico.close())
            .asPromise(feature)
        }}
        type={"dato tecnico"}
        dato={modalUpdateDatoTecnico.value}
        isOpen={modalUpdateDatoTecnico.isOpen}
        toggle={actionsModalUpdateDatoTecnico.toggle}
      /> */}
      {/* <ModalDelete
        isOpen={modalDeleteDatoTecnico.isOpen}
        toggle={actionsModalDeleteDatoTecnico.toggle}
        title={
          modalDeleteDatoTecnico.value ? `Rimuovere il dato tecnico?` : null
        }
        text={
          modalDeleteDatoTecnico.value
            ? `Sei sicuro di voler eliminare la il dato tecnico ${modalDeleteDatoTecnico.value.text}?`
            : null
        }
        onDelete={() => {
          return removeDatoTecnico
            .onSuccess(() => actionsModalDeleteDatoTecnico.close())
            .asPromise(modalDeleteDatoTecnico.value)
        }}
      />
      <ModalFormFeature
        save={(feature) => {
          return addVantaggio
            .onSuccess(() => actionsModalAddVantaggio.close())
            .asPromise({
              order: nextOrderFeatureVantaggioToUse,
              product: id,
              feature_type: VANTAGGI_KEY,
              ...feature,
            })
        }}
        type={"vantaggio"}
        isOpen={modalAddVantaggio.isOpen}
        toggle={actionsModalAddVantaggio.toggle}
      />
      <ModalFormFeature
        save={(feature) => {
          return updateVantaggio
            .onSuccess(() => actionsModalUpdateVantaggio.close())
            .asPromise(feature)
        }}
        type={"vantaggio"}
        dato={modalUpdateVantaggio.value}
        isOpen={modalUpdateVantaggio.isOpen}
        toggle={actionsModalUpdateVantaggio.toggle}
      />
      <ModalDelete
        isOpen={modalDeleteVantaggio.isOpen}
        toggle={actionsModalDeleteVantaggio.toggle}
        title={modalDeleteVantaggio.value ? `Rimuovere il vantaggio?` : null}
        text={
          modalDeleteVantaggio.value
            ? `Sei sicuro di voler eliminare la il vantaggio ${modalDeleteVantaggio.value.text}?`
            : null
        }
        onDelete={() => {
          return removeVantaggio
            .onSuccess(() => actionsModalDeleteVantaggio.close())
            .asPromise(modalDeleteVantaggio.value)
        }}
      /> */}
      <ModalFormVariant
        save={(variant) => {
          const payload = {
            ...variant,
            product: id,
            order: nextOrderVariantToUse,
          }
          return addCombo
            .onSuccess((combo) => {
              actionsModalAddVariant.close()
            })
            .asPromise(payload)
        }}
        attributesVariant={attributesVariant ? attributesVariant : []}
        isOpen={modalAddVariant.isOpen}
        toggle={actionsModalAddVariant.toggle}
      />
      <ModalFormVariant
        save={(variant) => {
          // const payload = { ...variant, product: id }
          return updateCombo
            .onSuccess((combo) => {
              actionsModalUpdateVariant.close()
            })
            .asPromise(variant)
        }}
        variant={modalUpdateVariant.value}
        attributesVariant={attributesVariant ? attributesVariant : []}
        isOpen={modalUpdateVariant.isOpen}
        toggle={actionsModalUpdateVariant.toggle}
      />
      <ModalDelete
        isOpen={modalDeleteVariant.isOpen}
        toggle={actionsModalDeleteVariant.toggle}
        title={modalDeleteVariant.value ? `Rimuovere la variante?` : null}
        text={
          modalDeleteVariant.value
            ? `Sei sicuro di voler eliminare la variante con codice ${modalDeleteVariant.value.code}?`
            : null
        }
        onDelete={() => {
          return removeCombo
            .onSuccess(() => actionsModalDeleteVariant.close())
            .asPromise(modalDeleteVariant.value)
        }}
      />
      <ModalMagazzino
        save={(variant) => {
          const variants = variant.variant_values.reduce((acc, variant) => {
            acc[variant.variant_attribute] = variant.value
            return acc
          }, {})
          const payload = {
            ...variant,
            variant_values_write: variants,
          }
          return updateCombo
            .onSuccess((combo) => {
              actionsModalMagazzino.close()
            })
            .asPromise(payload)
        }}
        variant={modalMagazzino.value}
        isOpen={modalMagazzino.isOpen}
        toggle={actionsModalMagazzino.toggle}
        onExited={() => {
          actionsModalMagazzino.onClosed()
        }}
      />
    </Layout>
  )
}
