import React from "react"
import Column from "../Column/Column"
import useDebounceQueryParams from "magik-react-hooks/useRouterDebounceQueryParams"
import {
  Card,
  CardContent,
  CardHeader,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core"
import { Storage } from "@material-ui/icons"
import { Pagination } from "@material-ui/lab"

export default function ListMagazzino({
  combos,
  onUpdate,
  columnProps,
  pagination,
}) {
  const [setQueryParams, debQueryParams] = useDebounceQueryParams()
  return (
    <Card>
      <CardHeader
        className={"card-header-no-border"}
        title="Magazzino prodotti"
      />
      <CardContent className="card-no-padding">
        <TableContainer component={Paper}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <Column {...columnProps} field="product__title">
                  Prodotto
                </Column>
                <Column {...columnProps} field="code">
                  Codice
                </Column>
                <Column {...columnProps} field="stock_quantity">
                  Quantità
                </Column>
                <TableCell>Azioni</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {combos &&
                combos.map((combo) => (
                  <TableRow
                    className={
                      combo.stock_quantity === 0 ? "background-red" : ""
                    }
                    key={combo.id}
                  >
                    <TableCell>{combo.product_data.title}</TableCell>
                    <TableCell>{combo.code}</TableCell>
                    <TableCell
                      className={combo.stock_quantity === 0 ? "text-red" : ""}
                    >
                      {combo.stock_quantity}
                    </TableCell>
                    <TableCell>
                      <div className='icons-list'>
                        <Storage
                          onClick={() => onUpdate(combo)}
                          size="small"
                          className="pointer"
                        />
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </CardContent>
      <Pagination
        className="pagination"
        count={pagination.numPages}
        color="primary"
        page={+debQueryParams.page || 1}
        onChange={(e, page) => setQueryParams({ page })}
      />
    </Card>
  )
}
