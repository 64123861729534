import { Card } from '@material-ui/core'
import { BugReport } from '@material-ui/icons'

export default function RunTimeErrorFallback() {
  return (
    <div
      style={{
        backgroundImage: 'url(/sfondo-login.png)',
        height: '100vh',
        backgroundSize: 'cover',
      }}
    >
      <div
        style={{
          paddingTop: '20%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Card
          style={{
            position: 'absolute',
            width: '50%',
            textAlign: 'center',
          }}
        >
          <h1>Ops, qualcosa è andato storto. <BugReport fontSize={'large'} /></h1>
          <small>Stiamo lavorando per risolvere il problema.</small>
        </Card>
      </div>
    </div>
  )
}
