import React, { useEffect, useState } from "react"
import { getIn } from "formik"
import { FormControlLabel, Switch, TextField } from "@material-ui/core"
import {
  KeyboardDatePicker,
  KeyboardTimePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers"
import moment from "moment"
import "moment/locale/it"
import MomentUtils from "@date-io/moment"
import ReactQuill from "react-quill"
import "react-quill/dist/quill.snow.css"
import "quill-mention"
import "quill-mention/dist/quill.mention.css"

export const InputField = ({
  field,
  form: { touched, errors },
  label,
  labelClassName,
  addonText,
  rowLayout = false,
  relaxed = false,
  noHelperText = false,
  onChange,
  ...props
}) => {
  const touch = getIn(touched, field.name)
  const error = getIn(errors, field.name)
  const { value: formValue, ...passField } = field
  let value
  if (relaxed && (formValue === undefined || formValue === null)) {
    value = ""
  } else {
    value = formValue
  }

  return (
    <TextField
      value={value}
      label={label ? label : undefined}
      variant="outlined"
      fullWidth
      {...passField}
      {...props}
      onChange={(e) => {
        if (onChange) {
          const shouldChange = onChange(e)
          if (shouldChange === false) {
            e.preventDefault()
            return
          }
        }
        passField.onChange(e)
      }}
      error={!!(error && touch)}
      helperText={noHelperText ? "" : !!(error && touch) ? error : undefined}
    />
  )
}

export const SwitchCheckboxField = ({
  field,
  label,
  form,
  checked,
  ...props
}) => {
  return (
    <FormControlLabel
      control={
        <Switch
          checked={field.value}
          onChange={form.handleChange}
          name={field.name}
        />
      }
      label={label}
    />
  )
}

export const DateField = ({ field, label, form, checked, ...props }) => {
  const touch = getIn(form.touched, field.name)
  const error = getIn(form.errors, field.name)
  return (
    <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
      <KeyboardDatePicker
        disableToolbar
        variant="dialog"
        cancelLabel={"Annulla"}
        format="DD/MM/YYYY"
        inputVariant="outlined"
        name={field.name}
        InputLabelProps={{ shrink: true }}
        error={!!(error && touch)}
        helperText={error && touch ? error : undefined}
        id={field.name}
        label={label}
        value={field.value}
        onChange={(date) =>
          form.setFieldValue(field.name, moment(date).format("YYYY-MM-DD"))
        }
        {...props}
      />
    </MuiPickersUtilsProvider>
  )
}

export const TimeField = ({ field, label, form, checked, ...props }) => {
  const touch = getIn(form.touched, field.name)
  const error = getIn(form.errors, field.name)
  const timeValue = field.value ? moment(field.value, "HH:mm") : null

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <KeyboardTimePicker
        ampm={false}
        disableToolbar
        variant="dialog"
        cancelLabel={"Annulla"}
        inputVariant="outlined"
        name={field.name}
        error={!!error}
        InputLabelProps={{ shrink: true }}
        helperText={error || touch ? error : undefined}
        id={field.name}
        label={label}
        value={timeValue}
        onChange={(time) => {
          if (time) {
            form.setFieldValue(field.name, time.format("HH:mm"))
          } else {
            form.setFieldValue(field.name, null)
          }
        }}
        {...props}
      />
    </MuiPickersUtilsProvider>
  )
}

export const RichTextNewsletterField = ({ field, ...props }) => {
  return (
    <ReactQuill
      {...props}
      // modules={quillModules}
      value={field.value}
      onChange={field.onChange(field.name)}
    />
  )
}

export const FileField = ({
  field,
  form: { touched, errors, setFieldValue },
  label,
  labelClassName,
  addonText,
  rowLayout = false,
  relaxed = false,
  noHelperText = false,
  onChange,
  accept,
  ...props
}) => {
  const touch = getIn(touched, field.name)
  const error = getIn(errors, field.name)
  const { value, ...passField } = field

  return (
    <TextField
      {...passField}
      {...props}
      variant="outlined"
      value={undefined}
      label={label ? label : undefined}
      fullWidth
      InputLabelProps={{
        shrink: true,
      }}
      InputProps={{
        // Is this the real life? Is this just fantasy?
        inputProps: {
          accept,
        },
      }}
      type="file"
      onChange={(e) => {
        setFieldValue(field.name, e.target.files[0] ?? null)
      }}
      error={!!(error && touch)}
      helperText={noHelperText ? "" : error && touch ? error : undefined}
    />
  )
}

export const ImageField = ({ field, ...props }) => {
  const { value } = field

  return (
    <>
      <FileField accept={"image/*"} field={field} {...props} />
      <ImagePreview fileOrImage={value} />
    </>
  )
}

function useObjectURL(file) {
  const [url, setUrl] = useState(null)

  useEffect(() => {
    if (file) {
      const createdUrl = URL.createObjectURL(file)
      setUrl(createdUrl)
      return () => {
        URL.revokeObjectURL(createdUrl)
      }
    } else {
      setUrl(null)
    }
  }, [file])

  return url
}

function ImagePreview({ fileOrImage }) {
  const url = useObjectURL(typeof fileOrImage === "string" ? null : fileOrImage)

  if (!fileOrImage) {
    return null
  }

  const previewUrl = typeof fileOrImage === "string" ? fileOrImage : url

  return (
    <div style={{ border: "1px solid #CCC", padding: 10, marginTop: 10 }}>
      <img
        // width="250"
        style={{ maxHeight: 150, maxWidth: "100%" }}
        src={previewUrl}
        alt="preview"
      />
    </div>
  )
}
