import {
  Card,
  CardContent,
  CardHeader,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from "@material-ui/core"
import {
  Add,
  ArrowDownward,
  ArrowUpward,
  Delete,
  Edit,
} from "@material-ui/icons"
import { arrayMoveImmutable } from "array-move"

export default function CardAttributesVariant({
  attributesVariant,
  productId,
  actionsModalAddAttributeVariant,
  actionsModalUpdateAttributeVariant,
  actionsModalDeleteAttributeVariant,
  sortAttributesVariant,
}) {
  return (
    <Card>
      <CardHeader
        title={
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div className="title-card-big">Varianti</div>
            <Add
              onClick={() => actionsModalAddAttributeVariant.open()}
              color={"primary"}
              className={"pointer"}
            />
          </div>
        }
      />
      <CardContent className={"card-variants"}>
        {attributesVariant && attributesVariant.length > 0 ? (
          <Table size="small">
            <TableBody>
              {attributesVariant.map((attr, i) => (
                <TableRow key={i}>
                  <TableCell className={"bg-varianti"}>{attr.name}</TableCell>
                  <TableCell>
                    <div className={"icons-list"}>
                      <ArrowUpward
                        className="pointer"
                        onClick={() => {
                          sortAttributesVariant(
                            productId,
                            arrayMoveImmutable(attributesVariant, i, i - 1)
                          )
                        }}
                        fontSize="small"
                      />
                      <ArrowDownward
                        className="pointer"
                        onClick={() => {
                          sortAttributesVariant(
                            productId,
                            arrayMoveImmutable(attributesVariant, i, i + 1)
                          )
                        }}
                        fontSize="small"
                      />
                      <Edit
                        onClick={() =>
                          actionsModalUpdateAttributeVariant.open(attr)
                        }
                        className="pointer"
                        fontSize="small"
                      />
                      <Delete
                        onClick={() =>
                          actionsModalDeleteAttributeVariant.open(attr)
                        }
                        className="pointer"
                        fontSize="small"
                      />
                    </div>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        ) : (
          <p>Non hai ancora inserito delle varianti per il prodotto.</p>
        )}
      </CardContent>
    </Card>
  )
}
