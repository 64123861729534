import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import React, { useCallback, useEffect, useState } from 'react'
import { useAuthActions, useAuthState } from 'use-eazy-auth'
import { isMobile } from 'react-device-detect'
import { Field, Formik } from 'formik'
import * as Yup from 'yup'
import { InputField, transformErrorForForm } from '../../components/Form'
import { KeyboardBackspace, Save } from '@material-ui/icons'
import ButtonSave from '../../components/ButtonSave'
import { passwordRecoverApi } from '../../state/passwordRecover'

const RecoverSchema = Yup.object().shape({
  email: Yup.string()
    .email('Inserisci un indirzzo email valido')
    .required('Inserisci un indirzzo email valido'),
})

function DialogRecover({ modalRecoverPassword, toggleModalRecover }) {
  return (
    <Dialog
      open={modalRecoverPassword}
      onClose={toggleModalRecover}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle onClose={toggleModalRecover}>Recupero Password</DialogTitle>

      <Formik
        validationSchema={RecoverSchema}
        initialValues={{ email: '' }}
        onSubmit={(values, actions) => {
          return passwordRecoverApi(values.email).then(
            () => {
              actions.setStatus({ email: values.email })
            },
            (error) => {
              actions.setErrors(transformErrorForForm(error))
            }
          )
        }}
      >
        {({ handleSubmit, isSubmitting, status }) => (
          <form onSubmit={handleSubmit}>
            <DialogContent dividers>
              <div style={{ marginBottom: 10 }}>
                Inserisci la tua e-mail per recuperare la tua password.
              </div>
              <Field
                disabled={!!status}
                label={'E-mail'}
                name="email"
                component={InputField}
              />
              {status && (
                <Alert
                  variant="filled"
                  color="success"
                  style={{ marginTop: 20 }}
                >
                  Se l'indirizzo <i>{status.email}</i> è associata ad un
                  account riceverai una mail. <br />
                  Apri il link al suo interno per resettare la tua password.
                </Alert>
              )}
            </DialogContent>
            <DialogActions
              style={{ display: 'flex', justifyContent: 'space-between' }}
            >
              <Button
                size="small"
                startIcon={<KeyboardBackspace />}
                variant="outlined"
                onClick={toggleModalRecover}
              >
                Chiudi
              </Button>
              {!status && (
                <ButtonSave
                  size="small"
                  disabled={isSubmitting}
                  type="submit"
                  startIcon={<Save />}
                  variant="outlined"
                >
                  {isSubmitting ? 'Recuperando...' : 'Recupera'}
                </ButtonSave>
              )}
            </DialogActions>
          </form>
        )}
      </Formik>
    </Dialog>
  )
}

export default function Login() {
  const { loginLoading, loginError } = useAuthState()
  const { login, clearLoginError } = useAuthActions()
  const [modalRecoverPassword, setModalRecoverPassword] = useState(false)

  // Clear login error when Login component unmount
  useEffect(() => () => clearLoginError(), [clearLoginError])

  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')

  const toggleModalRecover = useCallback(() => {
    setModalRecoverPassword((v) => !v)
  }, [])

  return (
    <div
      style={{
        backgroundImage: 'url(/sfondo-login.png)',
        height: '100vh',
        //width: '100%',
        backgroundSize: 'cover',
      }}
    >
      <form
        className="row mt-5 p-2"
        onSubmit={(e) => {
          e.preventDefault()
          if (username !== '' && password !== '') {
            login({ username, password })
          }
        }}
      >
        <Card
          style={{
            borderRadius: isMobile ? 0 : 4,
            margin: isMobile ? 0 : 'auto',
            position: 'absolute',
            top: '20%',
            left: 0,
            right: 0,
            width: isMobile ? '100%' : 500,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
          }}
        >
          <CardHeader
            className="card-login"
            title={<div style={{ textAlign: 'center' }}>PANNELLO ECOMMERCE</div>}
          />
          <CardContent>
            <div className="form-group" style={{ paddingTop: 20 }}>
              <TextField
                placeholder="@username"
                variant="outlined"
                type="username"
                fullWidth
                value={username}
                onChange={(e) => {
                  clearLoginError()
                  setUsername(e.target.value)
                }}
              />
            </div>
            <div style={{ marginTop: 10 }}>
              <TextField
                variant="outlined"
                placeholder="password"
                fullWidth
                type="password"
                value={password}
                onChange={(e) => {
                  clearLoginError()
                  setPassword(e.target.value)
                }}
              />
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div
                className="pointer link-login"
                style={{ marginTop: 20 }}
                onClick={toggleModalRecover}
              >
                Password dimenticata?{' '}
              </div>
              <Button
                style={{ marginTop: 10 }}
                variant="contained"
                type="submit"
                color="primary"
                disabled={loginLoading}
              >
                {!loginLoading ? 'Accedi' : 'Logged in...'}
              </Button>
            </div>
            {loginError && (
              <Alert variant="filled" color="error" style={{ marginTop: 20 }}>
                Bad combination of email and password.
              </Alert>
            )}
          </CardContent>
        </Card>
        <DialogRecover
          modalRecoverPassword={modalRecoverPassword}
          toggleModalRecover={toggleModalRecover}
        />
      </form>
    </div>
  )
}
