import React, { useMemo } from "react"
import useDebounceQueryParams from "magik-react-hooks/useRouterDebounceQueryParams"
import useModalTrigger from "magik-react-hooks/useModalTrigger"
import { useRunRj } from "react-rocketjump"
import { ProdottoState } from "../../state/prodotti"
import Layout from "../../components/Layout/Layout"
import ModalFormProdotto from "../../components/Prodotti/ModalFormProdotto"
import ModalDelete from "../../components/Modals/ModalDelete"
import ListProdotti from "../../components/Prodotti/ListProdotti"
import {
  TextField,
  Button,
  Card,
  CircularProgress,
  Grid,
  InputAdornment,
  IconButton,
} from "@material-ui/core"
import { Cancel } from "@material-ui/icons"
import { isMobile } from "react-device-detect"
import { CategoriaState } from "../../state/categorie"
import { useHistory } from "react-router-dom"

export default function Prodotti() {
  const [queryParams, setQueryParams, debQueryParams, setDebQueryParams] =
    useDebounceQueryParams()

  const [modalCreate, actionsModalCreate] = useModalTrigger()
  const [modalUpdate, actionsModalUpdate] = useModalTrigger()
  const [modalDelete, actionsModalDelete] = useModalTrigger()
  const { value: deleteProdotto } = modalDelete

  const history = useHistory()

  const onUpdate = (prodotto) => actionsModalUpdate.open(prodotto)
  const onDelete = (prodotto) => actionsModalDelete.open(prodotto)

  const filters = useMemo(() => {
    return { ...debQueryParams }
  }, [debQueryParams])

  const [
    { list, loading, pagination },
    { addProdotto, updateProdotto, removeProdotto },
  ] = useRunRj(ProdottoState, [filters], false)

  const columnProps = {
    value: queryParams.ordering,
    onChange: (value) => setDebQueryParams({ ordering: value }),
  }

  const [{ list: categories }] = useRunRj(CategoriaState)


  return (
    <Layout title="Prodotti">
      <div>
        <Card className="card-filters">
          <Grid container>
            <Grid item xs={12} md={10}>
              <div>
                <TextField
                  size="small"
                  placeholder="Cerca..."
                  value={queryParams.search ?? ""}
                  onChange={(e) =>
                    setDebQueryParams({
                      search: e.target.value,
                      page: 1,
                    })
                  }
                  style={{ width: isMobile ? "100%" : undefined }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          disabled={!queryParams.search}
                          aria-label="toggle password visibility"
                          onClick={() => setQueryParams(() => {})}
                          edge="end"
                        >
                          <Cancel
                            color={!queryParams.search ? undefined : "primary"}
                          />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  id="outlined-basic"
                  variant="outlined"
                />
              </div>
            </Grid>
            <Grid item xs={12} md={2}>
              <Button
                variant="outlined"
                color="primary"
                onClick={() => actionsModalCreate.open()}
              >
                Aggiungi Prodotto
              </Button>
            </Grid>
          </Grid>
        </Card>
        {list === null && loading && (
          <div style={{ textAlign: "center" }}>
            <CircularProgress color="primary" />
          </div>
        )}
        {list && (
          <ListProdotti
            columnProps={columnProps}
            pagination={pagination}
            prodotti={list}
            onUpdate={onUpdate}
            onDelete={onDelete}
          />
        )}
        <ModalFormProdotto
          save={(prodotto) => {
            return addProdotto
              .onSuccess((result) => {
                actionsModalCreate.close()
                history.push(`/prodotti/${result.id}`)
              })
              .asPromise({
                ...prodotto,
                categories: prodotto.categories.map((cat) => cat.id),
              })
          }}
          isOpen={modalCreate.isOpen}
          toggle={actionsModalCreate.toggle}
          categories={categories ? categories.filter(d => d.parent) : []}
          onExited={actionsModalCreate.onClosed}
        />
        <ModalFormProdotto
          save={(prodotto) => {
            return updateProdotto
              .onSuccess(() => actionsModalUpdate.close())
              .asPromise({
                ...prodotto,
                categories: prodotto.categories.map((cat) => cat.id),
              })
          }}
          prodotto={modalUpdate.value}
          isOpen={modalUpdate.isOpen}
          categories={categories ? categories.filter(d => d.parent) : []}
          toggle={actionsModalUpdate.toggle}
          onExited={() => {
            actionsModalUpdate.onClosed()
          }}
        />
        <ModalDelete
          isOpen={modalDelete.isOpen}
          toggle={actionsModalDelete.toggle}
          title={deleteProdotto ? `Rimuovere ${deleteProdotto.title}?` : null}
          text={
            deleteProdotto
              ? `Sei sicuro di voler eliminare il prodotto ${deleteProdotto.title}?`
              : null
          }
          onDelete={() => {
            return removeProdotto
              .onSuccess(() => actionsModalDelete.close())
              .asPromise(modalDelete.value)
          }}
          onExited={actionsModalDelete.onClosed}
        />
      </div>
    </Layout>
  )
}
