import React from "react"
import { Box, Typography } from "@material-ui/core"
import makeStyles from "@material-ui/core/styles/makeStyles"
import { useAuthUser } from "use-eazy-auth"
import { Person } from "@material-ui/icons"

const useStyles = makeStyles((theme) => ({
  root: {
    borderBottom: `solid 1px ${theme.palette.sidebar.borderColor}`,
    //position: 'fixed',
    backgroundColor: 'rgb(7, 130, 68, 0.1)',
    padding: 0,
    zIndex: 10000,
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    minHeight: 64,
  },
  userInfo: {
    transition: "all 0.1s ease",
    opacity: 1,
    ".Cmt-miniLayout .Cmt-sidebar-content:not(:hover) &": {
      height: 0,
      paddingTop: 0,
      opacity: 0,
      transition: "all 0.3s ease",
    },
  },
  userTitle: {
    color: theme.palette.sidebar.textDarkColor,
    marginBottom: 8,
    display: "flex",
    alignItems: "center",
  },
  userSubTitle: {
    fontSize: 20,
    fontWeight: theme.typography.fontWeightBold,
    letterSpacing: 0.25,
    display: "flex",
    alignItems: "center",
  },
}))

const SidebarHeader = () => {
  const classes = useStyles()
  const { user } = useAuthUser()

  return (
    <Box className={classes.root}>
      <Box className={classes.userInfo}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="flex-end"
        >
          <Box mr={2}>
            
            <Typography className={classes.userSubTitle}>
              <Person style={{ height: 30, marginRight: 5 }} /> {user.username}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default SidebarHeader
