import React, { useMemo } from "react"
import useDebounceQueryParams from "magik-react-hooks/useRouterDebounceQueryParams"
import useModalTrigger from "magik-react-hooks/useModalTrigger"
import { useRunRj } from "react-rocketjump"
import Layout from "../../components/Layout/Layout"
import {
  TextField,
  Card,
  CircularProgress,
  Grid,
  InputAdornment,
  IconButton,
} from "@material-ui/core"
import { Cancel } from "@material-ui/icons"
import { isMobile } from "react-device-detect"
import { ClienteState } from "../../state/clienti"
import ListClienti from "../../components/Clienti/ListClienti"
import ModalFormCliente from "../../components/Clienti/ModalFormCliente"
import ModalDelete from "../../components/Modals/ModalDelete"

export default function Clienti() {
  const [queryParams, setQueryParams, debQueryParams, setDebQueryParams] =
    useDebounceQueryParams()

  const [modalCreate, actionsModalCreate] = useModalTrigger()
  const [modalUpdate, actionsModalUpdate] = useModalTrigger()
  const [modalDelete, actionsModalDelete] = useModalTrigger()
  const { value: deleteCliente } = modalDelete

  const onUpdate = (cliente) => actionsModalUpdate.open(cliente)
  const onDelete = (cliente) => actionsModalDelete.open(cliente)

  const filters = useMemo(() => {
    return { ...debQueryParams }
  }, [debQueryParams])

  const [{ list, loading, pagination }, { addCustomer, updateCustomer, removeCustomer }] =
    useRunRj(ClienteState, [filters], false)

  const columnProps = {
    value: queryParams.ordering,
    onChange: (value) => setDebQueryParams({ ordering: value }),
  }

  return (
    <Layout title="Clienti">
      <div>
        <Card className="card-filters">
          <Grid container>
            <Grid item xs={12} md={10}>
              <div>
                <TextField
                  size="small"
                  placeholder="Cerca..."
                  value={queryParams.search ?? ""}
                  onChange={(e) =>
                    setDebQueryParams({
                      search: e.target.value,
                      page: 1,
                    })
                  }
                  style={{ width: isMobile ? "100%" : undefined }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          disabled={!queryParams.search}
                          aria-label="toggle password visibility"
                          onClick={() => setQueryParams(() => {})}
                          edge="end"
                        >
                          <Cancel
                            color={!queryParams.search ? undefined : "primary"}
                          />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  id="outlined-basic"
                  variant="outlined"
                />
              </div>
            </Grid>
            {/* <Grid item xs={12} md={2}>
              <Button
                variant="outlined"
                color="primary"
                onClick={() => actionsModalCreate.open()}
              >
                Aggiungi Cliente
              </Button>
            </Grid> */}
          </Grid>
        </Card>
        {list === null && loading && (
          <div style={{ textAlign: "center" }}>
            <CircularProgress color="primary" />
          </div>
        )}
        {list && (
          <ListClienti
            columnProps={columnProps}
            clienti={list}
            pagination={pagination}
            onUpdate={onUpdate}
            onDelete={onDelete}
          />
        )}
      </div>
      <ModalFormCliente
        save={(cliente) => {
          return addCustomer
            .onSuccess(() => {
              actionsModalCreate.close()
            })
            .asPromise(cliente)
        }}
        isOpen={modalCreate.isOpen}
        toggle={actionsModalCreate.toggle}
        onExited={actionsModalCreate.onClosed}
      />
      <ModalFormCliente
        save={(cliente) => {
          return updateCustomer
            .onSuccess(() => {
              actionsModalUpdate.close()
            })
            .asPromise(cliente)
        }}
        cliente={modalUpdate.value}
        isOpen={modalUpdate.isOpen}
        toggle={actionsModalUpdate.toggle}
        onExited={actionsModalUpdate.onClosed}
      />
      <ModalDelete
        isOpen={modalDelete.isOpen}
        toggle={actionsModalDelete.toggle}
        title={deleteCliente ? `Rimuovere ${deleteCliente.rag_sociale}?` : null}
        text={
          deleteCliente
            ? `Sei sicuro di voler eliminare il cliente ${deleteCliente.rag_sociale}?`
            : null
        }
        onDelete={() => {
          return removeCustomer
            .onSuccess(() => actionsModalDelete.close())
            .asPromise(modalDelete.value)
        }}
        onExited={actionsModalDelete.onClosed}
      />
    </Layout>
  )
}
