import * as Sentry from '@sentry/react'
import { AuthRoute, GuestRoute } from 'use-eazy-auth/routes'
import { Switch, BrowserRouter as Router, Redirect } from 'react-router-dom'
import Auth from './Auth'
import Login from './pages/Login'
import { ThemeProvider } from '@material-ui/core'
import theme from './theme'
import Prodotti from './pages/Prodotti'
import Categorie from './pages/Categorie'
import ProdottoDettaglio from './pages/ProdottoDettaglio'
import Magazzino from './pages/Magazzino/Magazzino'
import Ordini from './pages/Ordini'
import DettaglioOrdine from './pages/DettaglioOrdine'
import Clienti from './pages/Clienti'
import ClienteDettaglio from './pages/ClienteDettaglio'
import Video from './pages/Video'
// import Home from './pages/Home'
import Reset from './pages/Reset'
import Profile from './pages/Profile'
import ChangePassword from './pages/ChangePassword'
import RunTimeErrorFallback from './components/RunTimeErrorFallback'
import { getUserPermission } from './state/permissions'

function redirectCanViewProdotti(user) {
  return getUserPermission(user).canViewProdotti ? false : '/ordini'
}

function redirectCanViewCategorie(user) {
  return getUserPermission(user).canViewCategorie ? false : '/oridini'
}

function redirectCanViewMagazzino(user) {
  return getUserPermission(user).canViewMagazzino ? false : '/oridini'
}

function redirectCanViewClienti(user) {
  return getUserPermission(user).canViewClienti ? false : '/oridini'
}

function redirectCanViewOrdini(user) {
  return getUserPermission(user).canViewOrdini ? false : '/oridini'
}

function redirectCanViewVideo(user) {
  return getUserPermission(user).canViewVideo ? false : '/oridini'
}

function App() {
  return (
    <Sentry.ErrorBoundary fallback={<RunTimeErrorFallback />}>
      <ThemeProvider theme={theme}>
        <Auth>
          <Router>
            <Switch>
              <GuestRoute path="/reset/:token">
                <Reset />
              </GuestRoute>
              <GuestRoute path="/login">
                <Login />
              </GuestRoute>
              <AuthRoute path="/profile" exact>
                <Profile />
              </AuthRoute>
              <AuthRoute path="/change-password" exact>
                <ChangePassword />
              </AuthRoute>
              <AuthRoute
                path="/prodotti"
                exact
                redirectTest={redirectCanViewProdotti}
              >
                <Prodotti />
              </AuthRoute>
              <AuthRoute
                path="/prodotti/:id"
                exact
                redirectTest={redirectCanViewProdotti}
              >
                <ProdottoDettaglio />
              </AuthRoute>
              <AuthRoute
                path="/categorie"
                exact
                redirectTest={redirectCanViewCategorie}
              >
                <Categorie />
              </AuthRoute>
              <AuthRoute
                path="/magazzino"
                exact
                redirectTest={redirectCanViewMagazzino}
              >
                <Magazzino />
              </AuthRoute>
              <AuthRoute
                path="/clienti"
                exact
                redirectTest={redirectCanViewClienti}
              >
                <Clienti />
              </AuthRoute>
              <AuthRoute
                path="/clienti/:id"
                exact
                redirectTest={redirectCanViewClienti}
              >
                <ClienteDettaglio />
              </AuthRoute>
              <AuthRoute
                path="/ordini"
                exact
                redirectTest={redirectCanViewOrdini}
              >
                <Ordini />
              </AuthRoute>
              <AuthRoute path="/ordini/:id" exact redirectTest={redirectCanViewOrdini}>
                <DettaglioOrdine />
              </AuthRoute>
              <AuthRoute
                path="/video"
                exact
                redirectTest={redirectCanViewVideo}
              >
                <Video />
              </AuthRoute>
              <Redirect from="/" to="/ordini" />
            </Switch>
          </Router>
        </Auth>
      </ThemeProvider>
    </Sentry.ErrorBoundary>
  )
}

export default App
