import React, { useMemo } from "react"
import useDebounceQueryParams from "magik-react-hooks/useRouterDebounceQueryParams"
import useModalTrigger from "magik-react-hooks/useModalTrigger"
import { useRunRj } from "react-rocketjump"
import Layout from "../../components/Layout/Layout"
import ModalDelete from "../../components/Modals/ModalDelete"
import {
  TextField,
  Button,
  Card,
  CircularProgress,
  Grid,
  InputAdornment,
  IconButton,
} from "@material-ui/core"
import { Cancel } from "@material-ui/icons"
import { isMobile } from "react-device-detect"
import { VideoState } from "../../state/video"
import ModalFormVideo from "../../components/Video/ModalFormVideo"
import ListVideo from "../../components/Video/ListVideo"
import { usePermissions } from "../../state/permissions"
import { ProdottiAutocompleteState } from "../../state/prodotti"

export default function Video() {
  const [queryParams, setQueryParams, debQueryParams, setDebQueryParams] =
    useDebounceQueryParams()

  const [modalCreate, actionsModalCreate] = useModalTrigger()
  const [modalUpdate, actionsModalUpdate] = useModalTrigger()
  const [modalDelete, actionsModalDelete] = useModalTrigger()
  const { value: deleteVideo } = modalDelete

  const onUpdate = (video) => actionsModalUpdate.open(video)
  const onDelete = (video) => actionsModalDelete.open(video)

  const filters = useMemo(() => {
    return { ...debQueryParams }
  }, [debQueryParams])

  const [{ list, loading }, { addVideo, updateVideo, removeVideo }] = useRunRj(
    VideoState,
    [filters],
    false
  )

  const columnProps = {
    value: queryParams.ordering,
    onChange: (value) => setDebQueryParams({ ordering: value }),
  }

  const [{ list: prodotti }] = useRunRj(ProdottiAutocompleteState)

  const permissions = usePermissions()

  return (
    <Layout title="Video">
      <div>
        <Card className="card-filters">
          <Grid container>
            <Grid item xs={12} md={10}>
              <div>
                <TextField
                  size="small"
                  placeholder="Cerca..."
                  value={queryParams.search ?? ""}
                  onChange={(e) =>
                    setDebQueryParams({
                      search: e.target.value,
                    })
                  }
                  style={{ width: isMobile ? "100%" : undefined }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          disabled={!queryParams.search}
                          aria-label="toggle password visibility"
                          onClick={() => setQueryParams(() => {})}
                          edge="end"
                        >
                          <Cancel
                            color={!queryParams.search ? undefined : "primary"}
                          />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  id="outlined-basic"
                  variant="outlined"
                />
              </div>
            </Grid>
            {permissions.canWriteVideo && (
              <Grid item xs={12} md={2}>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => actionsModalCreate.open()}
                >
                  Aggiungi Video
                </Button>
              </Grid>
            )}
          </Grid>
        </Card>
        {list === null && loading && (
          <div style={{ textAlign: "center" }}>
            <CircularProgress color="primary" />
          </div>
        )}
        {list && (
          <ListVideo
            columnProps={columnProps}
            videos={list}
            onUpdate={onUpdate}
            onDelete={onDelete}
          />
        )}
        <ModalFormVideo
          save={(video) => {
            const products =
              video.products.length > 0 ? video.products.map((p) => p.id) : []
            return addVideo
              .onSuccess(() => {
                actionsModalCreate.close()
              })
              .asPromise({ ...video, products: products })
          }}
          prodotti={prodotti || []}
          isOpen={modalCreate.isOpen}
          toggle={actionsModalCreate.toggle}
          onExited={actionsModalCreate.onClosed}
        />
        <ModalFormVideo
          save={(video) => {
            const products =
              video.products.length > 0 ? video.products.map((p) => p.id) : []
            return updateVideo
              .onSuccess(() => actionsModalUpdate.close())
              .asPromise({ ...video, products: products })
          }}
          video={modalUpdate.value}
          prodotti={prodotti || []}
          isOpen={modalUpdate.isOpen}
          toggle={actionsModalUpdate.toggle}
          onExited={() => {
            actionsModalUpdate.onClosed()
          }}
        />
        <ModalDelete
          isOpen={modalDelete.isOpen}
          toggle={actionsModalDelete.toggle}
          title={deleteVideo ? `Rimuovere ${deleteVideo.title}?` : null}
          text={
            deleteVideo
              ? `Sei sicuro di voler eliminare il video ${deleteVideo.title}?`
              : null
          }
          onDelete={() => {
            return removeVideo
              .onSuccess(() => actionsModalDelete.close())
              .asPromise(modalDelete.value)
          }}
          onExited={actionsModalDelete.onClosed}
        />
      </div>
    </Layout>
  )
}
