import React, { useMemo } from "react"
import * as Yup from "yup"
import { Field, Formik } from "formik"
import {
  InputField,
  setNullOnEmptyValues,
  transformErrorForForm,
} from "../Form"
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Button,
} from "@material-ui/core"
import { KeyboardBackspace } from "@material-ui/icons"
import ButtonSave from "../ButtonSave"
import { Transition } from "../../consts"

const INITIAL_VALUES = {
  code: "",
  price: "",
  variant_values_write: {},
}

const ProdottoSchema = Yup.object().shape({
  code: Yup.string().required("Codice è obbligatorio"),
  // value: Yup.string().required("Valore è obbligatorio"),
})

export default function ModalFormVariant({
  variant,
  attributesVariant,
  save,
  isOpen,
  toggle,
  onExited,
}) {
  const initialValuesVariants = useMemo(() => {
    if (!variant || variant.variant_values.length === 0) {
      return {}
    }
    return variant.variant_values.reduce((acc, variant) => {
      acc[variant.variant_attribute] = variant.value
      return acc
    }, {})
  }, [variant])

  return (
    <Dialog open={isOpen} fullWidth onClose={toggle} TransitionComponent={Transition}>
      <DialogTitle onClose={toggle}>
        {variant ? "Modifica" : "Inserimento"} combinazione
      </DialogTitle>
      <Formik
        validationSchema={ProdottoSchema}
        initialValues={
          variant
            ? { ...variant, variant_values_write: initialValuesVariants }
            : INITIAL_VALUES
        }
        onSubmit={(values, actions) => {
          save(setNullOnEmptyValues(values)).catch((err) => {
            actions.setErrors(transformErrorForForm(err))
          })
        }}
      >
        {({ handleSubmit, isSubmitting, values, setFieldValue }) => {
          return (
            <form onSubmit={handleSubmit}>
              <DialogContent dividers>
                <Grid container spacing={3}>
                  <Grid item md={12} xs={12}>
                    <Field
                      label={"Codice"}
                      name="code"
                      component={InputField}
                    />
                  </Grid>
                  <Grid item md={12} xs={12}>
                    <Field
                      label={"Prezzo"}
                      name="price"
                      component={InputField}
                    />
                  </Grid>
                  {attributesVariant &&
                    attributesVariant.length > 0 &&
                    attributesVariant.map((attribute, i) => (
                      <Grid key={i} item md={12} xs={12}>
                        <Field
                          label={attribute.name}
                          name={`variant_values_write[${attribute.id}]`}
                          component={InputField}
                        />
                      </Grid>
                    ))}
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button
                  startIcon={<KeyboardBackspace />}
                  variant="outlined"
                  onClick={toggle}
                >
                  Chiudi
                </Button>
                <ButtonSave
                  // disabled={isSubmitting}
                  type="submit"
                  variant="outlined"
                >
                  {variant ? "Modifica" : "Aggiungi"}
                </ButtonSave>
              </DialogActions>
            </form>
          )
        }}
      </Formik>
    </Dialog>
  )
}
