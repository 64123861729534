import React from "react"
import * as Yup from "yup"
import { Field, Formik } from "formik"
import {
  DateField,
  fixInitialValuesNull,
  InputField,
  setNullOnEmptyValues,
  transformErrorForForm,
} from "../Form"
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Button,
  MenuItem,
} from "@material-ui/core"
import { KeyboardBackspace } from "@material-ui/icons"
import ButtonSave from "../ButtonSave"
import { STATES_ORDER, Transition } from "../../consts"

const INITIAL_VALUES = {
  state: "",
  data_ricezione: '',
  data_spedizione: '',
}

const ProdottoSchema = Yup.object().shape({
  state: Yup.string().required("Stato ordine è obbligatorio"),
  // value: Yup.string().required("Valore è obbligatorio"),
})

export default function ModalUpdateStato({
  ordine,
  save,
  isOpen,
  toggle,
  onExited,
}) {
  return (
    <Dialog
      open={isOpen}
      fullWidth
      onClose={toggle}
      TransitionComponent={Transition}
    >
      <DialogTitle onClose={toggle}>
        Aggiorna Stato Ordine N° # {ordine && ordine.id}
      </DialogTitle>
      <Formik
        validationSchema={ProdottoSchema}
        initialValues={ordine ? fixInitialValuesNull(ordine) : INITIAL_VALUES}
        onSubmit={(values, actions) => {
          save(setNullOnEmptyValues(values)).catch((err) => {
            actions.setErrors(transformErrorForForm(err))
          })
        }}
      >
        {({ handleSubmit, isSubmitting, values, setFieldValue }) => {
          return (
            <form onSubmit={handleSubmit}>
              <DialogContent dividers>
                <Grid container spacing={3}>
                  <Grid item md={12} xs={12}>
                    <Field
                      label={"Stato"}
                      name="state"
                      component={InputField}
                      select
                      value={values.state}
                    >
                      {Object.keys(STATES_ORDER).map((state, i) => (
                        <MenuItem value={state} key={i}>
                          {STATES_ORDER[state]}
                        </MenuItem>
                      ))}
                    </Field>
                  </Grid>
                  {values.state === "ricevuto" && (
                    <Grid item md={12} xs={12}>
                      <Field
                        label={"Data ricezione"}
                        name="data_ricezione"
                        fullWidth
                        component={DateField}
                      />
                    </Grid>
                  )}
                  {values.state === "spedito" && (
                    <Grid item md={12} xs={12}>
                      <Field
                        label={"Data spedizione"}
                        name="data_spedizione"
                        fullWidth
                        component={DateField}
                      />
                    </Grid>
                  )}
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button
                  startIcon={<KeyboardBackspace />}
                  variant="outlined"
                  onClick={toggle}
                >
                  Chiudi
                </Button>
                <ButtonSave
                  // disabled={isSubmitting}
                  type="submit"
                  variant="outlined"
                >
                  Aggiorna Stato
                </ButtonSave>
              </DialogActions>
            </form>
          )
        }}
      </Formik>
    </Dialog>
  )
}
