import React, { useMemo } from "react"
import useDebounceQueryParams from "magik-react-hooks/useRouterDebounceQueryParams"
import useModalTrigger from "magik-react-hooks/useModalTrigger"
import { useRunRj } from "react-rocketjump"
import Layout from "../../components/Layout/Layout"
import {
  TextField,
  Card,
  CircularProgress,
  Grid,
  InputAdornment,
  IconButton,
  Button,
} from "@material-ui/core"
import { Cancel } from "@material-ui/icons"
import { isMobile } from "react-device-detect"
import { OrderState } from "../../state/orders"
import ListOrdini from "../../components/Ordini/ListOrdini"
import ModalFormOrder from "../../components/Ordini/ModalFormOrder"
import { ClientiAutocompleteState } from "../../state/clienti"
import ModalDelete from "../../components/Modals/ModalDelete"
import { useHistory } from "react-router"
import { usePermissions } from "../../state/permissions"

export default function Ordini() {
  const [queryParams, setQueryParams, debQueryParams, setDebQueryParams] =
    useDebounceQueryParams()

  const history = useHistory()

  const [modalCreate, actionsModalCreate] = useModalTrigger()
  const [modalDelete, actionsModalDelete] = useModalTrigger()
  const { value: deleteOrdine } = modalDelete

  const onDelete = (order) => actionsModalDelete.open(order)

  const filters = useMemo(() => {
    return { ...debQueryParams }
  }, [debQueryParams])

  const [{ list, loading, pagination }, { addOrdine, removeOrdine }] = useRunRj(
    OrderState,
    [filters],
    false
  )

  const [{ list: clienti }] = useRunRj(ClientiAutocompleteState)

  const columnProps = {
    value: queryParams.ordering,
    onChange: (value) => setDebQueryParams({ ordering: value }),
  }

  const permissions = usePermissions()

  return (
    <Layout title="Ordini">
      <div>
        <Card className="card-filters">
          <Grid container>
            <Grid item xs={12} md={10}>
              <div>
                <TextField
                  size="small"
                  placeholder="Cerca..."
                  value={queryParams.search ?? ""}
                  onChange={(e) =>
                    setDebQueryParams({
                      search: e.target.value,
                      page: 1,
                    })
                  }
                  style={{ width: isMobile ? "100%" : undefined }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          disabled={!queryParams.search}
                          aria-label="toggle password visibility"
                          onClick={() => setQueryParams(() => {})}
                          edge="end"
                        >
                          <Cancel
                            color={!queryParams.search ? undefined : "primary"}
                          />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  id="outlined-basic"
                  variant="outlined"
                />
              </div>
            </Grid>
            {permissions.canWriteOrdini && (
              <Grid item xs={12} md={2}>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => actionsModalCreate.open()}
                >
                  Aggiungi Ordine
                </Button>
              </Grid>
            )}
          </Grid>
        </Card>
        {list === null && loading && (
          <div style={{ textAlign: "center" }}>
            <CircularProgress color="primary" />
          </div>
        )}
        {list && (
          <ListOrdini
            columnProps={columnProps}
            ordini={list}
            pagination={pagination}
            onDelete={onDelete}
          />
        )}
      </div>
      {clienti && (
        <ModalFormOrder
          save={(ordine) => {
            return addOrdine
              .onSuccess((order) => {
                actionsModalCreate.close()
                history.push(`/ordini/${order.id}`)
              })
              .asPromise({ ...ordine, customer: ordine.customer.id })
          }}
          isOpen={modalCreate.isOpen}
          clienti={clienti}
          toggle={actionsModalCreate.toggle}
          onExited={actionsModalCreate.onClosed}
        />
      )}
      <ModalDelete
        isOpen={modalDelete.isOpen}
        toggle={actionsModalDelete.toggle}
        title={
          deleteOrdine ? `Rimuovere l'ordine N° # ${deleteOrdine.id}?` : null
        }
        text={
          deleteOrdine
            ? `Sei sicuro di voler eliminare l'ordine N° # ${deleteOrdine.id}  per il cliente ${deleteOrdine.rag_sociale}?`
            : null
        }
        onDelete={() => {
          return removeOrdine
            .onSuccess(() => actionsModalDelete.close())
            .asPromise(modalDelete.value)
        }}
        onExited={actionsModalDelete.onClosed}
      />
    </Layout>
  )
}
