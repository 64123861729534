import { CircularProgress, Grid } from "@material-ui/core"
import { useRunRj } from "react-rocketjump"
import useModalTrigger from "magik-react-hooks/useModalTrigger"
import { useParams } from "react-router"
import { useMemo } from "react"
import { ClienteAddressState, ClienteDetailState } from "../../state/clienti"
import Layout from "../../components/Layout/Layout"
import CardDetailCliente from "../../components/Clienti/CardDetailCliente"
import CardAddresses from "../../components/Clienti/CardAddresses"
import ListOrdini from "../../components/Ordini/ListOrdini"
import { OrderState } from "../../state/orders"
import ModalDelete from "../../components/Modals/ModalDelete"
import ModalFormAddress from "../../components/Clienti/ModalFormAddress"

export default function ClienteDettaglio() {
  const { id } = useParams()
  const filters = useMemo(() => {
    return { cliente: id }
  }, [id])
  const [{ data: cliente }] = useRunRj(ClienteDetailState, [id])

  const [{ list: addresses }, { addAddress, updateAddress, removeAddress }] =
    useRunRj(ClienteAddressState, [filters], false)

  const [{ list: ordini, pagination }, { removeOrdine }] = useRunRj(
    OrderState,
    [filters],
    false
  )

  const [modalDelete, actionsModalDelete] = useModalTrigger()
  const [modalAddAddress, actionsModalAddAddress] = useModalTrigger()
  const [modalUpdateAddress, actionsModalUpdateAddress] = useModalTrigger()
  const [modalDeleteAddress, actionsModalDeleteAddress] = useModalTrigger()
  const { value: deleteOrdine } = modalDelete
  const { value: deleteAddress } = modalDeleteAddress

  const onDelete = (combo) => actionsModalDelete.open(combo)

  return (
    <Layout title={"Dettaglio Cliente"}>
      {(!cliente || !ordini) && (
        <div style={{ textAlign: "center" }}>
          <CircularProgress color="primary" />
        </div>
      )}
      {cliente && addresses && ordini && (
        <Grid container spacing={4}>
          <Grid md={4} item>
            <CardDetailCliente cliente={cliente} />
          </Grid>
          <Grid item md={8}>
            <CardAddresses
              addresses={addresses}
              actionsModalDeleteAddress={actionsModalDeleteAddress}
              actionsModalUpdateAddress={actionsModalUpdateAddress}
              actionsModalAddAddress={actionsModalAddAddress}
            />
          </Grid>
          <Grid item md={12}>
            {ordini && (
              <ListOrdini
                onDelete={onDelete}
                pagination={pagination}
                ordini={ordini}
              />
            )}
          </Grid>
        </Grid>
      )}
      <ModalDelete
        isOpen={modalDelete.isOpen}
        toggle={actionsModalDelete.toggle}
        title={
          deleteOrdine ? `Rimuovere l'ordine N° # ${deleteOrdine.id}?` : null
        }
        text={
          deleteOrdine
            ? `Sei sicuro di voler eliminare l'ordine N° # ${deleteOrdine.id}  per il cliente ${deleteOrdine.rag_sociale}?`
            : null
        }
        onDelete={() => {
          return removeOrdine
            .onSuccess(() => actionsModalDelete.close())
            .asPromise(modalDelete.value)
        }}
        onExited={actionsModalDelete.onClosed}
      />
      <ModalFormAddress
        save={(address) => {
          return addAddress
            .onSuccess(() => {
              actionsModalAddAddress.close()
            })
            .asPromise({
              ...address,
              customer: id,
            })
        }}
        isOpen={modalAddAddress.isOpen}
        toggle={actionsModalAddAddress.toggle}
        onExited={actionsModalAddAddress.onClosed}
      />
      <ModalFormAddress
        save={(address) => {
          return updateAddress
            .onSuccess(() => {
              actionsModalUpdateAddress.close()
            })
            .asPromise(address)
        }}
        address={modalUpdateAddress.value}
        isOpen={modalUpdateAddress.isOpen}
        toggle={actionsModalUpdateAddress.toggle}
        onExited={actionsModalUpdateAddress.onClosed}
      />
      <ModalDelete
        isOpen={modalDeleteAddress.isOpen}
        toggle={actionsModalDeleteAddress.toggle}
        title={deleteAddress ? `Rimuovere l'indirizzo?` : null}
        text={
          deleteAddress
            ? `Sei sicuro di voler eliminare l'indirizzo ${
                deleteAddress.indirizzo
              }  per il cliente ${cliente && cliente.rag_sociale}?`
            : null
        }
        onDelete={() => {
          return removeAddress
            .onSuccess(() => actionsModalDeleteAddress.close())
            .asPromise(modalDeleteAddress.value)
        }}
        onExited={actionsModalDeleteAddress.onClosed}
      />
    </Layout>
  )
}
