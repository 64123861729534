import React from "react"
import useDebounceQueryParams from "magik-react-hooks/useRouterDebounceQueryParams"
import Column from "../Column/Column"
import {
  Card,
  CardContent,
  CardHeader,
  Chip,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core"
import { Delete, Search } from "@material-ui/icons"
import { Pagination } from "@material-ui/lab"
import moment from "moment"
import { useHistory } from "react-router-dom"
import { STATES_ORDER } from "../../consts"
import { usePermissions } from "../../state/permissions"

export default function ListOrdini({
  ordini,
  onUpdate,
  onDelete,
  columnProps,
  pagination,
}) {
  const [queryParams, setQueryParams, debQueryParams, setDebQueryParams] =
    useDebounceQueryParams()
  const history = useHistory()
  const permissions = usePermissions()

  return (
    <Card>
      <CardHeader title="Ordini" className={"card-header-no-border"} />
      <CardContent className={"card-no-padding"}>
        <TableContainer component={Paper}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <Column {...columnProps} field="pk">
                  Numero ordine
                </Column>
                <Column {...columnProps} field="data_creazione">
                  Data Creazione
                </Column>
                <Column {...columnProps} field="data_ricezione">
                  Data Ricezione
                </Column>
                <Column {...columnProps} field="customer__rag_sociale">
                  Ragione Sociale
                </Column>
                <Column {...columnProps} field="state">
                  Stato
                </Column>
                <Column {...columnProps} field="total">
                  Totale
                </Column>
                <TableCell>Azioni</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {ordini &&
                ordini.map((ordine) => (
                  <TableRow key={ordine.id}>
                    <TableCell># {ordine.id}</TableCell>
                    <TableCell>
                      {moment(ordine.data_creazione).format("DD/MM/YYYY")}
                    </TableCell>
                    <TableCell>
                      {ordine.data_ricezione
                        ? moment(ordine.data_ricezione).format("DD/MM/YYYY")
                        : ""}
                    </TableCell>
                    <TableCell>{ordine.rag_sociale}</TableCell>
                    <TableCell>
                      <Chip
                        color="primary"
                        size="small"
                        variant="outlined"
                        label={STATES_ORDER[ordine.state]}
                      />
                    </TableCell>
                    <TableCell>
                      {ordine.total ? `${ordine.total} €` : ""}
                    </TableCell>
                    <TableCell>
                      <div className={"icons-list"}>
                        <Search
                          onClick={() => history.push(`/ordini/${ordine.id}`)}
                          className="pointer"
                          fontSize="small"
                        />
                        {permissions.canWriteOrdini && (
                          <Delete
                            onClick={() => onDelete(ordine)}
                            className="pointer"
                            fontSize="small"
                          />
                        )}
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </CardContent>
      <Pagination
        className="pagination"
        count={pagination.numPages}
        color="primary"
        page={+debQueryParams.page || 1}
        onChange={(e, page) => setQueryParams({ page })}
      />
    </Card>
  )
}
