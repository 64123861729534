import {
  Card,
  CardContent,
  CardHeader,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from "@material-ui/core"
import { Delete } from "@material-ui/icons"
import { usePermissions } from "../../state/permissions"

export default function CardAddresses({
  addresses,
  actionsModalAddAddress,
  actionsModalDeleteAddress,
  actionsModalUpdateAddress,
}) {
  const permissions = usePermissions()
  return (
    <Card>
      <CardHeader
        title={
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div className="title-card-big">Indirizzi cliente</div>
            {/* <Add
              color={"primary"}
              className={"pointer"}
              onClick={() => actionsModalAddAddress.open()}
            /> */}
          </div>
        }
      />
      <CardContent style={{ maxHeight: 82, minHeight: 82 }}>
        {addresses && addresses.length > 0 ? (
          <Table size="small">
            <TableBody>
              <TableRow>
                <TableCell className={"bg-varianti"}>Città</TableCell>
                <TableCell className={"bg-varianti"}>Indirizzo</TableCell>
                <TableCell className={"bg-varianti"}>Cap</TableCell>
                <TableCell className={"bg-varianti"}>Provincia</TableCell>
                {permissions.canWriteClienti && (
                  <TableCell className={"bg-varianti"}>Azioni</TableCell>
                )}
              </TableRow>
              {addresses.map((address, i) => (
                <TableRow key={i}>
                  <TableCell>{address.citta}</TableCell>
                  <TableCell>{address.indirizzo}</TableCell>
                  <TableCell>{address.cap}</TableCell>
                  <TableCell>{address.provincia}</TableCell>
                  <TableCell>
                    <div className={"icons-list"}>
                      {/* <Edit
                        onClick={() => actionsModalUpdateAddress.open(address)}
                        className="pointer"
                        fontSize="small"
                      /> */}
                      {permissions.canWriteClienti && (
                        <Delete
                          onClick={() =>
                            actionsModalDeleteAddress.open(address)
                          }
                          className="pointer"
                          fontSize="small"
                        />
                      )}
                    </div>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        ) : (
          <p>Non ci sono indirizzi per il cliente.</p>
        )}
      </CardContent>
    </Card>
  )
}
