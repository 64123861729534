import React, { useState } from 'react'
import {
  Card,
  CircularProgress,
  DialogContent,
  DialogTitle,
} from '@material-ui/core'
import { Link, useParams } from 'react-router-dom'
import { Formik, Field } from 'formik'
import { isMobile } from 'react-device-detect'
import { transformErrorForForm, InputField } from '../../components/Form'
import * as Yup from 'yup'
import {
  useCheckResetToken,
  resetPasswordApi,
} from '../../state/passwordRecover'
import { Alert } from '@material-ui/lab'
import ButtonSave from '../../components/ButtonSave'

const ResetSchema = Yup.object().shape({
  password: Yup.string().required(),
  repeatPassword: Yup.string()
    .required()
    .oneOf([Yup.ref('password'), null], 'Le password non corrispondono.'),
})

function ResetForm({ token, onPasswordResetted }) {
  return (
    <div>
      <Formik
        initialValues={{
          password: '',
          repeatPassword: '',
        }}
        validationSchema={ResetSchema}
        onSubmit={(values, actions) => {
          actions.setStatus(null)
          return resetPasswordApi(token, values.password).then(
            () => {
              actions.setSubmitting(false)
              onPasswordResetted()
            },
            (error) => {
              actions.setSubmitting(false)
              actions.setErrors(transformErrorForForm(error))
            }
          )
        }}
        render={({ handleSubmit, isSubmitting, errors }) => (
          <form onSubmit={handleSubmit}>
            <Field
              margin="normal"
              label="Password"
              type="password"
              name="password"
              component={InputField}
            />
            <Field
              margin="normal"
              label="Conferma Password"
              type="password"
              name="repeatPassword"
              component={InputField}
            />
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <ButtonSave
                disabled={isSubmitting}
                variant="outlined"
                style={{ marginTop: 20 }}
              >
                Reimposta Password
              </ButtonSave>
            </div>
            {errors.token && (
              <Alert color="error" style={{ marginTop: 20 }}>
                {errors.token ?? 'Errore durante reimposta password.'}
              </Alert>
            )}
          </form>
        )}
      />
    </div>
  )
}

export default function Reset() {
  const { token } = useParams()
  const [{ data: isTokenOk, error, pending }] = useCheckResetToken(token)

  const [passwordResetted, setPasswordResetted] = useState(false)

  return (
    <div
      style={{
        backgroundImage: 'url(/sfondo-login.png)',
        height: '100vh',
        //width: '100%',
        backgroundSize: 'cover',
      }}
    >
      <Card
        style={{
          borderRadius: isMobile ? 0 : 4,
          margin: isMobile ? 0 : 'auto',
          position: 'absolute',
          top: '20%',
          left: 0,
          right: 0,
          width: isMobile ? '100%' : 500,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
      >
        <DialogTitle>Reimposta Password</DialogTitle>
        <DialogContent dividers>
          {pending && (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <CircularProgress />
            </div>
          )}
          {error && (
            <Alert color="error">
              Link non valido o scaudo.
              <br />
              Vai al <Link to="/login">login</Link>.
            </Alert>
          )}
          {isTokenOk && !passwordResetted && (
            <ResetForm
              token={token}
              onPasswordResetted={() => setPasswordResetted(true)}
            />
          )}
          {passwordResetted && (
            <Alert color="success">
              Password re impostata con successo.
              <br />
              Vai al <Link to="/login">login</Link>.
            </Alert>
          )}
        </DialogContent>
      </Card>
    </div>
  )
}
