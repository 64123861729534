import React from "react"
import * as Yup from "yup"
import { Field, Formik } from "formik"
import {
  InputField,
  setNullOnEmptyValues,
  transformErrorForForm,
} from "../Form"
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Button,
} from "@material-ui/core"
import { KeyboardBackspace } from "@material-ui/icons"
import ButtonSave from "../ButtonSave"
import { Transition } from "../../consts"

const INITIAL_VALUES = {
  indirizzo: "",
  citta: "",
  cap: "",
  provincia: "",
}

const AddressSchema = Yup.object().shape({
  indirizzo: Yup.string().required("Indirizzo è obbligatorio"),
  citta: Yup.string().required("Città è obbligatoria"),
  cap: Yup.string().required("Cap è obbligatorio"),
  provincia: Yup.string().required("Provincia è obbligatorio"),
})

export default function ModalFormAddress({ address, save, isOpen, toggle }) {
  return (
    <Dialog open={isOpen} onClose={toggle} TransitionComponent={Transition}>
      <DialogTitle onClose={toggle}>
        {address ? "Modifica" : "Inserimento"} indirizzo
      </DialogTitle>
      <Formik
        validationSchema={AddressSchema}
        initialValues={address ? address : INITIAL_VALUES}
        onSubmit={(values, actions) => {
          save(setNullOnEmptyValues(values)).catch((err) => {
            actions.setErrors(transformErrorForForm(err))
          })
        }}
      >
        {({ handleSubmit, isSubmitting, values, setFieldValue }) => {
          return (
            <form onSubmit={handleSubmit}>
              <DialogContent dividers>
                <Grid container spacing={3}>
                  <Grid item md={12}>
                    <Field
                      name="indirizzo"
                      InputLabelProps={{ shrink: true }}
                      label="Indirizzo"
                      component={InputField}
                    />
                  </Grid>
                  <Grid item md={6}>
                    <Field
                      name="citta"
                      InputLabelProps={{ shrink: true }}
                      label="Città"
                      component={InputField}
                    />
                  </Grid>
                  <Grid item md={4}>
                    <Field
                      name="cap"
                      InputLabelProps={{ shrink: true }}
                      label="Cap"
                      component={InputField}
                    />
                  </Grid>
                  <Grid item md={2}>
                    <Field
                      name="provincia"
                      InputLabelProps={{ shrink: true }}
                      label="Provincia"
                      component={InputField}
                    />
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button
                  startIcon={<KeyboardBackspace />}
                  variant="outlined"
                  onClick={toggle}
                >
                  Chiudi
                </Button>
                <ButtonSave
                  disabled={isSubmitting}
                  type="submit"
                  variant="outlined"
                >
                  {address ? "Modifica" : "Aggiungi"}
                </ButtonSave>
              </DialogActions>
            </form>
          )
        }}
      </Formik>
    </Dialog>
  )
}
