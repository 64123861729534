import React from 'react'
import useDebounceQueryParams from 'magik-react-hooks/useRouterDebounceQueryParams'
import Column from '../Column/Column'
import {
  Card,
  CardContent,
  CardHeader,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core'
import { Check, Clear, Delete, Edit, Search } from '@material-ui/icons'
import { Pagination } from '@material-ui/lab'
import { useHistory } from 'react-router-dom'

export default function ListProdotti({
  prodotti,
  onUpdate,
  onDelete,
  columnProps,
  pagination,
}) {
  const [queryParams, setQueryParams, debQueryParams, setDebQueryParams] =
    useDebounceQueryParams()
  const history = useHistory()

  return (
    <Card>
      <CardHeader title="Prodotti" className={'card-header-no-border'} />
      <CardContent className={'card-no-padding'}>
        <TableContainer component={Paper}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <Column {...columnProps} field="title">
                  Nome
                </Column>
                <TableCell>Immagine</TableCell>
                <Column {...columnProps} field="category">
                  Categoria
                </Column>
                <Column {...columnProps} field="active">
                  Attivo?
                </Column>
                <TableCell>Azioni</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {prodotti &&
                prodotti.map((prodotto) => (
                  <TableRow key={prodotto.id}>
                    <TableCell
                      onClick={() => history.push(`/prodotti/${prodotto.id}`)}
                      className="pointer"
                    >
                      <span className='text-primary'>{prodotto.title}</span>
                    </TableCell>
                    <TableCell>
                      {prodotto.image && (
                        <img
                          src={prodotto.image}
                          alt={prodotto.title}
                          height={50}
                        />
                      )}
                    </TableCell>
                    <TableCell>
                      {prodotto.categories_data
                        ? prodotto.categories_data.map((categoria) => (
                            <div key={categoria.id}>{categoria.name}</div>
                          ))
                        : ''}
                    </TableCell>
                    <TableCell>
                      {prodotto.active ? (
                        <Check color="primary" />
                      ) : (
                        <Clear color="secondary" />
                      )}
                    </TableCell>
                    <TableCell>
                      <div className={'icons-list'}>
                        <Search
                          onClick={() =>
                            history.push(`/prodotti/${prodotto.id}`)
                          }
                          className="pointer"
                          fontSize="small"
                        />
                        <Edit
                          onClick={() => onUpdate(prodotto)}
                          className="pointer"
                          fontSize="small"
                        />
                        <Delete
                          onClick={() => onDelete(prodotto)}
                          className="pointer"
                          fontSize="small"
                        />
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </CardContent>
      <Pagination
        className="pagination"
        count={pagination.numPages}
        color="primary"
        page={+debQueryParams.page || 1}
        onChange={(e, page) => setQueryParams({ page })}
      />
    </Card>
  )
}
