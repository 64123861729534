import { Card, CardContent, CardHeader, Chip, Grid } from "@material-ui/core"
import { Edit } from "@material-ui/icons"
import ReactHtmlParser from "react-html-parser"

export default function CardDetailProduct({
  prodotto,
  actionsModalUpdateProdotto,
  actionsModalAssociaVideo,
}) {
  return (
    <Card>
      <CardHeader
        title={
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div className="title-card-big">{prodotto.title}</div>
            <div>
              <Edit
                style={{ marginLeft: 20 }}
                onClick={() => actionsModalUpdateProdotto.open(prodotto)}
                color="primary"
                className={"pointer"}
              />
            </div>
          </div>
        }
      />
      <CardContent>
        <Grid container spacing={4}>
          <Grid item md={2}>
            {prodotto.image && (
              <img width="100%" src={prodotto.image} alt={prodotto.title} />
            )}
          </Grid>
          <Grid item md={10} xs={12}>
            <div className="descriptionProduct">
              {prodotto.description ? ReactHtmlParser(prodotto.description) : ''}
            </div>
            <div>
              {prodotto.categories_data && prodotto.categories_data.length > 0 && (
                <div style={{ marginTop: 10 }}>
                  {prodotto.categories_data.map((cat) => (
                    <Chip label={cat.name} key={cat.id} color={"primary"} />
                  ))}
                </div>
              )}
            </div>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}
