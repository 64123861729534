import React from "react"
import Column from "../Column/Column"
import {
  Card,
  CardContent,
  CardHeader,
  Chip,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core"
import { Delete, Edit } from "@material-ui/icons"
import { usePermissions } from "../../state/permissions"

export default function ListVideo({ videos, onUpdate, onDelete, columnProps }) {
  const permissions = usePermissions()
  return (
    <Card>
      <CardHeader title="Video" className={"card-header-no-border"} />
      <CardContent className={"card-no-padding"}>
        <TableContainer component={Paper}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <Column {...columnProps} field="title">
                  Titolo
                </Column>
                <TableCell>Url</TableCell>
                <TableCell>Prodotti</TableCell>
                {permissions.canWriteVideo && <TableCell>Azioni</TableCell>}
              </TableRow>
            </TableHead>
            <TableBody>
              {videos &&
                videos.map((video) => (
                  <TableRow key={video.id}>
                    <TableCell>{video.title}</TableCell>
                    <TableCell>{video.url}</TableCell>
                    <TableCell>
                      {video.products_data.length > 0
                        ? video.products_data.map((p) => (
                            <Chip
                              key={p.id}
                              variant="default"
                              color="primary"
                              label={p.title}
                            />
                          ))
                        : ""}
                    </TableCell>
                    {permissions.canWriteVideo && (
                      <TableCell>
                        <div className={"icons-list"}>
                          <Edit
                            onClick={() => onUpdate(video)}
                            className="pointer"
                            fontSize="small"
                          />
                          <Delete
                            onClick={() => onDelete(video)}
                            className="pointer"
                            fontSize="small"
                          />
                        </div>
                      </TableCell>
                    )}
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </CardContent>
    </Card>
  )
}
