import { Slide } from "@material-ui/core"
import { forwardRef } from "react"

export const PAGE_SIZE = process.env.NODE_ENV === 'production' ? 100 : 20
export const PAGE_SIZE_BIG = 1000

export const VANTAGGI_KEY = "commercial"
export const DATI_TECNICI_KEY = "technincal"

export const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

export const STATES_ORDER = {
  bozza: "Bozza",
  in_attesa: "In attesa",
  confermato: "Confermato (In attesa di pagamento)",
  rifiutato: "Rifiutato",
  pagato: "Pagato (In attesa di spedizione)",
  spedito: "Spedito",
  ricevuto: "Ricevuto",
}
