import React from "react"
import {
  Dialog,
  DialogActions,
  DialogTitle,
  Button,
  DialogContent,
} from "@material-ui/core"
import { ExitToApp, KeyboardBackspace } from "@material-ui/icons"
import { useAuthActions } from "use-eazy-auth"
import { Transition } from "../../consts"

export default function ModalLogout({ isOpen, toggle, onExited }) {
  const { logout } = useAuthActions()
  return (
    <Dialog open={isOpen} fullWidth={true} onClose={toggle} TransitionComponent={Transition}>
      <DialogTitle onClose={toggle}>Logout</DialogTitle>
      <DialogContent dividers>
        Sei sicuro di voler uscire dall'applicazione?
      </DialogContent>
      <DialogActions
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <Button
          startIcon={<KeyboardBackspace />}
          variant="outlined"
          onClick={toggle}
        >
          Annulla
        </Button>
        <Button
          type="submit"
          title='Logout'
          color='primary'
          variant="contained"
          onClick={() => {
            logout()
          }}
          startIcon={<ExitToApp />}
        >
          Esci
        </Button>
      </DialogActions>
    </Dialog>
  )
}
