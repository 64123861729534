import React, { useMemo } from "react"
import useDebounceQueryParams from "magik-react-hooks/useRouterDebounceQueryParams"
import useModalTrigger from "magik-react-hooks/useModalTrigger"
import { useRunRj } from "react-rocketjump"
import { CategoriaState } from "../../state/categorie"
import Layout from "../../components/Layout/Layout"
import ModalFormCategoria from "../../components/Categorie/ModalFormCategoria"
import ModalDelete from "../../components/Modals/ModalDelete"
import ListCategorie from "../../components/Categorie/ListCategorie"
import {
  TextField,
  Button,
  Card,
  CircularProgress,
  Grid,
  InputAdornment,
  IconButton,
} from "@material-ui/core"
import { Cancel } from "@material-ui/icons"
import { isMobile } from "react-device-detect"

export default function Categorie() {
  const [queryParams, setQueryParams, debQueryParams, setDebQueryParams] =
    useDebounceQueryParams()

  const [modalCreate, actionsModalCreate] = useModalTrigger()
  const [modalUpdate, actionsModalUpdate] = useModalTrigger()
  const [modalDelete, actionsModalDelete] = useModalTrigger()
  const { value: deleteCategoria } = modalDelete

  const onUpdate = (categoria) => actionsModalUpdate.open(categoria)
  const onDelete = (categoria) => actionsModalDelete.open(categoria)

  const filters = useMemo(() => {
    return { ...debQueryParams }
  }, [debQueryParams])

  const [
    { list, loading },
    { addCategoria, updateCategoria, removeCategoria },
  ] = useRunRj(CategoriaState, [filters], false)

  const [
    { list: categories },
  ] = useRunRj(CategoriaState)

  const columnProps = {
    value: queryParams.ordering,
    onChange: (value) => setDebQueryParams({ ordering: value }),
  }

  const firstLevelCategories = useMemo(() => {
    if (!categories) {
      return {}
    }
    return categories.filter((cat) => !cat.parent)
  }, [categories])

  return (
    <Layout title="Categorie">
      <div>
        <Card className="card-filters">
          <Grid container>
            <Grid item xs={12} md={10}>
              <div>
                <TextField
                  size="small"
                  placeholder="Cerca..."
                  value={queryParams.search ?? ""}
                  onChange={(e) =>
                    setDebQueryParams({
                      search: e.target.value,
                    })
                  }
                  style={{ width: isMobile ? "100%" : undefined }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          disabled={!queryParams.search}
                          aria-label="toggle password visibility"
                          onClick={() => setQueryParams(() => {})}
                          edge="end"
                        >
                          <Cancel
                            color={!queryParams.search ? undefined : "primary"}
                          />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  id="outlined-basic"
                  variant="outlined"
                />
              </div>
            </Grid>
            <Grid item xs={12} md={2}>
              <Button
                variant="outlined"
                color="primary"
                onClick={() => actionsModalCreate.open()}
              >
                Aggiungi Categoria
              </Button>
            </Grid>
          </Grid>
        </Card>
        {list === null && loading && (
          <div style={{ textAlign: "center" }}>
            <CircularProgress color="primary" />
          </div>
        )}
        {list && (
          <ListCategorie
            columnProps={columnProps}
            categorie={list}
            onUpdate={onUpdate}
            onDelete={onDelete}
          />
        )}
        <ModalFormCategoria
          save={(categoria) => {
            return addCategoria
              .onSuccess(() => {
                actionsModalCreate.close()
              })
              .asPromise({
                ...categoria,
                parent: categoria.parent ? categoria.parent.id : null,
              })
          }}
          isOpen={modalCreate.isOpen}
          categories={firstLevelCategories}
          toggle={actionsModalCreate.toggle}
          onExited={actionsModalCreate.onClosed}
        />
        <ModalFormCategoria
          save={(categoria) => {
            return updateCategoria
              .onSuccess(() => actionsModalUpdate.close())
              .asPromise({
                ...categoria,
                parent: categoria.parent ? categoria.parent.id : null,
              })
          }}
          categoria={modalUpdate.value}
          categories={firstLevelCategories}
          isOpen={modalUpdate.isOpen}
          toggle={actionsModalUpdate.toggle}
          onExited={() => {
            actionsModalUpdate.onClosed()
          }}
        />
        <ModalDelete
          isOpen={modalDelete.isOpen}
          toggle={actionsModalDelete.toggle}
          title={deleteCategoria ? `Rimuovere ${deleteCategoria.name}?` : null}
          text={
            deleteCategoria
              ? `Sei sicuro di voler eliminare la categoria ${deleteCategoria.name}?`
              : null
          }
          onDelete={() => {
            return removeCategoria
              .onSuccess(() => actionsModalDelete.close())
              .asPromise(modalDelete.value)
          }}
          onExited={actionsModalDelete.onClosed}
        />
      </div>
    </Layout>
  )
}
