import { ajax } from "rxjs/ajax"
import { rj } from "react-rocketjump"
import { map } from "rxjs/operators"
import rjPlainList from "react-rocketjump/plugins/plainList"
import { API_URL } from "../consts"

export const ProfileAdminState = rj(rjPlainList(), {
    name: "ProfileAdminState",
    computed: {
      profile: "getData",
      loading: "isLoading",
    },
    mutations: {
      updateProfile: {
        effect: (token) => (rowData) =>
          ajax({
            method: "PUT",
            url: `/api/commerce/profile-admin/`,
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
            body: JSON.stringify(rowData),
          }).pipe(map((r) => r.response)),
        updater: "updateData",
      },
    },
    effectCaller: rj.configured(),
  })