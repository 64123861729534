import React, { useMemo } from "react"
import * as Yup from "yup"
import { Field, Formik } from "formik"
import {
  fixInitialValuesNull,
  InputField,
  setNullOnEmptyValues,
  SwitchCheckboxField,
  transformErrorForForm,
} from "../Form"
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Button,
  TextField,
} from "@material-ui/core"
import { KeyboardBackspace } from "@material-ui/icons"
import ButtonSave from "../ButtonSave"
import { Autocomplete } from "@material-ui/lab"
import { ImageField } from "../Form"
import { RichTextNewsletterField } from "../Form"
import { Transition } from "../../consts"

const INITIAL_VALUES = {
  title: "",
  categories: [],
  description: "",
  image: "",
  active: true,
}

const ProdottoSchema = Yup.object().shape({
  title: Yup.string().required("Nome è obbligatorio"),
  categories: Yup.array().required("Categoria obbligatoria").min(1, 'Cateogoria obbligatoria'),
})

export default function ModalFormProdotto({
  prodotto,
  categories,
  save,
  isOpen,
  toggle,
  onExited,
}) {
  const categoriesInitials = useMemo(() => {
    if (
      prodotto &&
      prodotto.categories_data &&
      prodotto.categories_data.length > 0
    ) {
      return prodotto.categories_data.map((categoria) => ({
        id: categoria.id,
        nome: categoria.name,
      }))
    }
    return []
  }, [prodotto])

  const categoriesOptions = useMemo(() => {
    if (categories) {
      return categories.map((categoria) => ({
        id: categoria.id,
        nome: categoria.name,
      }))
    }
    return []
  }, [categories])

  return (
    <Dialog
      fullWidth={true}
      maxWidth={"md"}
      open={isOpen}
      onClose={toggle}
      TransitionComponent={Transition}
    >
      <DialogTitle onClose={toggle}>
        {prodotto ? "Modifica" : "Inserimento"} prodotto
      </DialogTitle>
      <Formik
        validationSchema={ProdottoSchema}
        initialValues={
          prodotto
            ? fixInitialValuesNull({
                ...prodotto,
                categories: categoriesInitials,
              })
            : INITIAL_VALUES
        }
        onSubmit={(values, actions) =>
          save(setNullOnEmptyValues(values)).catch((err) => {
            actions.setErrors(transformErrorForForm(err))
          })
        }
      >
        {({
          handleSubmit,
          isSubmitting,
          setFieldValue,
          values,
          errors,
          touched,
          setTouched,
        }) => (
          <form onSubmit={handleSubmit}>
            <DialogContent dividers>
              <Grid container spacing={3}>
                <Grid item md={12} xs={12}>
                  <Field size='small' label={"Nome"} name="title" component={InputField} />
                </Grid>
                <Grid item md={12} xs={12}>
                  <Field
                    label={"Immagine"}
                    size='small' 
                    variant="outlined"
                    name="image"
                    component={ImageField}
                  />
                </Grid>
                <Grid item md={12} xs={12}>
                  {categoriesOptions && (
                    <Autocomplete
                      multiple
                      noOptionsText={"Nessuna categoria trovato"}
                      value={values.categories}
                      id="size-small-standard-multi"
                      size="small"
                      name="categories"
                      onChange={(e, value) =>
                        setFieldValue("categories", value)
                      }
                      onBlur={() => setTouched({ categories: true })}
                      getOptionSelected={(option, value) =>
                        option.id === value.id
                      }
                      options={categoriesOptions}
                      getOptionLabel={(option) => option.nome}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          error={
                            !!errors["categories"] && touched["categories"]
                          }
                          helperText={
                            !!errors["categories"] && touched["categories"]
                              ? errors["categories"]
                              : undefined
                          }
                          placeholder="Aggiungi Categoria"
                        />
                      )}
                    />
                  )}
                </Grid>
                <Grid item md={12} xs={12}>
                  <Field
                    name="description"
                    type='textarea'
                    label={"Descrizione"}
                    className="MessaggioRighText"
                    multiline
                    size='small' 
                    rows={4}
                    component={InputField}
                  />
                </Grid>
                <Grid item md={12} xs={12}>
                  <Field
                    label="Attivo?"
                    component={SwitchCheckboxField}
                    name="active"
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button
                startIcon={<KeyboardBackspace />}
                variant="outlined"
                onClick={toggle}
              >
                Chiudi
              </Button>
              <ButtonSave
                disabled={isSubmitting}
                type="submit"
                variant="outlined"
              >
                {prodotto ? "Modifica" : "Aggiungi"}
              </ButtonSave>
            </DialogActions>
          </form>
        )}
      </Formik>
    </Dialog>
  )
}
