import {
  AppBar,
  Drawer,
  List,
  makeStyles,
  Toolbar,
  Hidden,
  Typography,
  IconButton,
} from '@material-ui/core'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { useAuthUser } from 'use-eazy-auth'
import useModalTrigger from 'magik-react-hooks/useModalTrigger'
import NavMenuItem from '../NavMenuItem'
import {
  AccountCircle,
  AttachMoney,
  ExitToApp,
  ListAlt,
  Menu,
  People,
  Store,
} from '@material-ui/icons'
import SidebarHeader from './SidebarHeader'
import { isMobile } from 'react-device-detect'
import ModalLogout from '../Modals/ModalLogout'
import { usePermissions } from '../../state/permissions'

const drawerWidth = 260

const useStyles = makeStyles((theme) => {
  return {
    root: {
      display: 'flex',
    },
    appBar: {
      // left: drawerWidth,
      [theme.breakpoints.up('md')]: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
      },
    },
    drawer: {
      // width: drawerWidth,
      [theme.breakpoints.up('md')]: {
        width: drawerWidth,
        flexShrink: 0,
      },
    },
    drawerPaper: {
      width: drawerWidth,
      background: '#FFF',
      color: '#000',
      boxShadow:
        '0px 1px 8px rgb(0 0 0 / 20%), 0px 3px 3px rgb(0 0 0 / 12%), 0px 3px 4px rgb(0 0 0 / 14%)',
      transition: 'all 0.3s ease',
    },
    container: {
      flex: 1,
      padding: isMobile ? 10 : 20,
    },
    offset: theme.mixins.toolbar,
    logout: {
      position: 'absolute',
      right: 20,
      display: 'flex',
      alignItems: 'center',
      top: 20,
      cursor: 'pointer',
      fontSize: 14,
      fontWeight: 700,
    },
    menuButton: {
      marginRight: theme.spacing(4),
      [theme.breakpoints.up('md')]: {
        display: 'none',
      },
    },
    scrollbarRoot: {
      height: (props) => `calc(100vh - ${props.height}px)`,
    },
    navMenuItem: {
      padding: '0 16px 0 0',
      position: 'relative',
      '.Cmt-miniLayout .Cmt-sidebar-content:not(:hover) &': {
        paddingLeft: 16,
      },
    },
    navMenuLink: {
      display: 'flex',
      alignItems: 'center',
      padding: '7px 16px 9px 32px',
      borderTopRightRadius: 30,
      borderBottomRightRadius: 30,
      color: theme.palette.sidebar.textColor,
      '&:hover, &:focus': {
        color: theme.palette.sidebar.textDarkColor,
        backgroundColor: theme.palette.sidebar.navHoverBgColor,
        '& .Cmt-icon-root, & .Cmt-nav-text': {
          color: theme.palette.sidebar.textDarkColor,
        },
      },
      '&.active': {
        color: theme.palette.sidebar.textActiveColor,
        backgroundColor: theme.palette.sidebar.navActiveBgColor,
        '& .Cmt-icon-root, & .Cmt-nav-text': {
          color: theme.palette.sidebar.textActiveColor,
        },
        '&:hover, &:focus': {
          '& .Cmt-nav-text, & .Cmt-icon-root': {
            color: theme.palette.sidebar.textActiveColor,
          },
        },
      },
      '.Cmt-miniLayout .Cmt-sidebar-content:not(:hover) &': {
        justifyContent: 'center',
        padding: 0,
        height: 40,
        width: 40,
        borderRadius: '50%',
        marginLeft: 4,
        marginRight: 4,
      },
    },
    navText: {
      flex: 1,
      fontSize: 14,
      '.Cmt-miniLayout .Cmt-sidebar-content:not(:hover) &': {
        display: 'none',
      },
      fontWeight: 600,
    },
    iconRoot: {
      marginRight: 16,
      fontSize: 20,
      '.Cmt-miniLayout .Cmt-sidebar-content:not(:hover) &': {
        marginRight: 0,
      },
    },
    titleHeader: {
      fontSize: 25,
      paddingLeft: 20,
      fontWeight: 500,
      color: '#FFFFFF',
    },
  }
})

const DrawerContent = () => {
  const permissions = usePermissions()
  return (
    <>
      <SidebarHeader />

      {/* <List >
        <NavMenuItem exact name="Home" link="/" icon={<Home />} />
      </List> */}
      <List>
        <NavMenuItem
          exact
          name="Profilo"
          link="/profile"
          icon={<AccountCircle />}
        />
      </List>
      <List>
        {permissions.canViewCategorie && (
          <NavMenuItem
            exact
            name="Categorie"
            link="/categorie"
            icon={<ListAlt />}
          />
        )}
      </List>
      {permissions.canViewProdotti && (
        <List>
          <NavMenuItem name="Prodotti" link="/prodotti" icon={<Store />} />
        </List>
      )}
      {/* {permissions.canViewMagazzino && (
        <List>
          <NavMenuItem
            exact
            name="Magazzino"
            link="/magazzino"
            icon={<Storage />}
          />
        </List>
      )} */}
      {permissions.canViewClienti && (
        <List>
          <NavMenuItem exact name="Clienti" link="/clienti" icon={<People />} />
        </List>
      )}
      {permissions.canViewOrdini && (
        <List>
          <NavMenuItem
            exact
            name="Ordini"
            link="/ordini"
            icon={<AttachMoney />}
          />
        </List>
      )}
      {/* {permissions.canViewVideo && (
        <List>
          <NavMenuItem exact name="Video" link="/video" icon={<Videocam />} />
        </List>
      )} */}
    </>
  )
}

export default function Layout({ children, title }) {
  const { user } = useAuthUser()
  const [modalLogout, actionsLogout] = useModalTrigger()

  const container =
    window !== undefined ? () => window.document.body : undefined

  const [mobileOpen, setMobileOpen] = useState(false)

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen)
  }

  const classes = useStyles()

  return (
    <div className={classes.root}>
      <AppBar color={'primary'} position="fixed" className={classes.appBar}>
        <Toolbar>
          <IconButton
            style={{ color: '#FFF' }}
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            className={classes.menuButton}
          >
            <Menu />
          </IconButton>
          <Link to={'/'} className={classes.titleHeader}>
            QR LEGNO ECOMMERCE
          </Link>
          {title && !isMobile && (
            <>
              <Typography variant="h1" style={{ marginLeft: 10 }} noWrap>
                /
              </Typography>
              <Typography
                variant="h3"
                style={{ marginLeft: 10, marginTop: 3, fontWeight: 400 }}
              >
                {title}
              </Typography>
            </>
          )}
        </Toolbar>
        <div onClick={() => actionsLogout.open()} className={classes.logout}>
          <ExitToApp style={{ marginRight: 5, height: 14 }} />{' '}
          {!isMobile && (
            <Typography style={{ fontSize: 14, fontWeight: 700 }}>
              Logout
            </Typography>
          )}
        </div>
      </AppBar>
      <nav className={classes.drawer} aria-label="mailbox folders">
        <Hidden mdUp implementation="css">
          <Drawer
            container={container}
            variant="temporary"
            anchor={'left'}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            <DrawerContent />
          </Drawer>
        </Hidden>
        <Hidden smDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            anchor="left"
          >
            <DrawerContent />
          </Drawer>
        </Hidden>
      </nav>

      <div className={classes.container}>
        <div className={classes.offset} />
        {children}
      </div>
      <ModalLogout
        isOpen={modalLogout.isOpen}
        toggle={actionsLogout.toggle}
        onExited={() => {
          actionsLogout.onClosed()
        }}
      />
    </div>
  )
}
