import React, { useMemo } from "react"
import * as Yup from "yup"
import { Field, Formik } from "formik"
import {
  InputField,
  setNullOnEmptyValues,
  transformErrorForForm,
} from "../Form"
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Button,
  TextField,
} from "@material-ui/core"
import { KeyboardBackspace } from "@material-ui/icons"
import ButtonSave from "../ButtonSave"
import { Transition } from "../../consts"
import { Autocomplete } from "@material-ui/lab"

const INITIAL_VALUES = {
  title: "",
  description: "",
  url: "",
  products: []
}

const ProdottoSchema = Yup.object().shape({
  title: Yup.string().required("Nome è obbligatorio"),
  description: Yup.string().required("Descrizione è obbligatoria"),
  url: Yup.string().required("Url Video è obbligatorio"),
})

export default function ModalFormVideo({
  video,
  save,
  isOpen,
  prodotti,
  toggle,
  onExited,
}) {
  const prodottiInitials = useMemo(() => {
    if (video && video.products_data && video.products_data.length > 0) {
      return video.products_data.map((product) => ({
        id: product.id,
        title: product.title,
      }))
    }
    return []
  }, [video])

  const prodottiOptions = useMemo(() => {
    if (prodotti) {
      return prodotti.map((product) => ({
        id: product.id,
        title: product.title,
      }))
    }
    return []
  }, [prodotti])

  return (
    <Dialog open={isOpen} onClose={toggle} TransitionComponent={Transition}>
      <DialogTitle onClose={toggle}>
        {video ? "Modifica" : "Inserimento"} video
      </DialogTitle>
      <Formik
        validationSchema={ProdottoSchema}
        initialValues={
          video ? { ...video, products: prodottiInitials } : INITIAL_VALUES
        }
        onSubmit={(values, actions) => {
          save(setNullOnEmptyValues(values)).catch((err) => {
            actions.setErrors(transformErrorForForm(err))
          })
        }}
      >
        {({
          handleSubmit,
          isSubmitting,
          values,
          setFieldValue,
          errors,
          touched,
        }) => {
          return (
            <form onSubmit={handleSubmit}>
              <DialogContent dividers>
                <Grid container spacing={3}>
                  <Grid item md={12} xs={12}>
                    <Field
                      label={"Titolo"}
                      name="title"
                      component={InputField}
                    />
                  </Grid>
                  <Grid item md={12} xs={12}>
                    <Field
                      label={"Descrizione"}
                      name="description"
                      rows={4}
                      component={InputField}
                    />
                  </Grid>
                  <Grid item md={12} xs={12}>
                    <Field label={"Url"} name="url" component={InputField} />
                  </Grid>
                  <Grid item md={12} xs={12}>
                    <Autocomplete
                      multiple
                      noOptionsText={"Nessun prodotto trovato"}
                      value={values.products}
                      id="size-small-standard-multi"
                      size="small"
                      name="tags"
                      onChange={(e, value) => setFieldValue("products", value)}
                      getOptionSelected={(option, value) =>
                        option.id === value.id
                      }
                      options={prodottiOptions}
                      getOptionLabel={(option) => option.title}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          label="Prodotti"
                          placeholder="Associa prodotti"
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button
                  startIcon={<KeyboardBackspace />}
                  variant="outlined"
                  onClick={toggle}
                >
                  Chiudi
                </Button>
                <ButtonSave
                  disabled={isSubmitting}
                  type="submit"
                  variant="outlined"
                >
                  {video ? "Modifica" : "Aggiungi"}
                </ButtonSave>
              </DialogActions>
            </form>
          )
        }}
      </Formik>
    </Dialog>
  )
}
