import { Card, CardContent, CardHeader, Chip, Grid } from "@material-ui/core"
import { Edit } from "@material-ui/icons"
import moment from "moment"
import { STATES_ORDER } from "../../consts"
import { usePermissions } from "../../state/permissions"

export default function CardDetailOrder({
  ordine,
  actionsModalUpdateStato,
  actionsModalUpdateOrder,
}) {
  const permissions = usePermissions()
  return (
    <Card>
      <CardHeader
        title={
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div className="title-card-big">
              # {ordine.id} - {ordine.rag_sociale}
            </div>
            {permissions.canWriteOrdini && (
              <Edit
                onClick={() => actionsModalUpdateOrder.open(ordine)}
                color="primary"
                className={"pointer"}
              />
            )}
          </div>
        }
      />
      <CardContent>
        <Grid container spacing={4}>
          <Grid item md={3}>
            {ordine.rag_sociale}
            <br />
            {ordine.indirizzo}
            <br />
            {ordine.cap} {ordine.citta}{" "}
            {ordine.provincia && `(${ordine.provincia})`}
          </Grid>
          <Grid item md={3}>
            P. IVA: {ordine.piva} <br />
            Cod. Fatturazione: {ordine.cod_fatturazione} <br />
          </Grid>
          <Grid item md={3}>
            Stato Ordine: <br />
            <Chip
              size={"medium"}
              className={"pointer"}
              variant={"outlined"}
              color={"primary"}
              onClick={() =>
                permissions.canWriteOrdini
                  ? actionsModalUpdateStato.open(ordine)
                  : {}
              }
              label={STATES_ORDER[ordine.state]}
            />
          </Grid>
          <Grid item md={3}>
            {ordine.total && (
              <div>
                Data ordine:{" "}
                <strong>
                  {moment(ordine.data_creazione).format("DD/MM/YYYY")}
                </strong>
                {ordine.data_spedizione && (
                  <div>
                    Data spedizione:{" "}
                    <strong>
                      {moment(ordine.data_spedizione).format("DD/MM/YYYY")}
                    </strong>
                  </div>
                )}
                {ordine.data_ricezione && (
                  <div>
                    Data ricezione:{" "}
                    <strong>
                      {moment(ordine.data_ricezione).format("DD/MM/YYYY")}
                    </strong>
                  </div>
                )}
                <div>
                  Costo spedizione: <strong>{ordine.prezzo_spedizione} &euro;</strong>
                </div>
                <div>
                  Totale merce: <strong>{ordine.total - ordine.prezzo_spedizione} &euro;</strong>
                </div>
                <div>
                  Totale ordine: <strong>{ordine.total} &euro;</strong>
                </div>
              </div>
            )}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}
