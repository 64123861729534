import { Card, CardContent, CardHeader, Grid } from "@material-ui/core"

export default function CardDetailCliente({ cliente }) {
  return (
    <Card>
      <CardHeader
        title={
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div className="title-card-big">{cliente.rag_sociale}</div>
          </div>
        }
      />
      <CardContent>
        <Grid container spacing={4}>
          <Grid item md={12}>
            {cliente.rag_sociale}
            <br />
            P. IVA: {cliente.piva} <br />
            Cod. Fatturazione: {cliente.cod_fatturazione} <br />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}
