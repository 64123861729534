import {
  Card,
  CardContent,
  CardHeader,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from "@material-ui/core"
import {
  Add,
  ArrowDownward,
  ArrowUpward,
  Delete,
  Edit,
} from "@material-ui/icons"
import { arrayMoveImmutable } from "array-move"

export default function CardAttributes({
  attributes,
  actionsModalAddAttribute,
  actionsModalUpdateAttribute,
  actionsModalDeleteAttribute,
  sortAttributes,
  productId,
}) {
  return (
    <Card>
      <CardHeader
        title={
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div className="title-card-big">Attributi</div>
            <Add
              color={"primary"}
              className={"pointer"}
              onClick={() => actionsModalAddAttribute.open()}
            />
          </div>
        }
      />
      <CardContent className={"card-variants"}>
        {attributes && attributes.length > 0 ? (
          <Table size="small">
            <TableBody>
              {attributes.map((attr, i) => (
                <TableRow key={i}>
                  <TableCell className={"bg-varianti"}>{attr.name}</TableCell>
                  <TableCell className={"bg-dati-tecnici"}>
                    {attr.value}
                  </TableCell>
                  <TableCell>
                    <div className={"icons-list"}>
                      <ArrowUpward
                        className="pointer"
                        onClick={() => {
                          sortAttributes(
                            productId,
                            arrayMoveImmutable(attributes, i, i - 1)
                          )
                        }}
                        fontSize="small"
                      />
                      <ArrowDownward
                        className="pointer"
                        onClick={() => {
                          sortAttributes(
                            productId,
                            arrayMoveImmutable(attributes, i, i + 1)
                          )
                        }}
                        fontSize="small"
                      />
                      <Edit
                        onClick={() => actionsModalUpdateAttribute.open(attr)}
                        className="pointer"
                        fontSize="small"
                      />
                      <Delete
                        onClick={() => actionsModalDeleteAttribute.open(attr)}
                        className="pointer"
                        fontSize="small"
                      />
                    </div>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        ) : (
          <p>Non hai ancora inserito degli attributi per il prodotto.</p>
        )}
      </CardContent>
    </Card>
  )
}
