import {
  Card,
  CardContent,
  CardHeader,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from '@material-ui/core'
import {
  Add,
  ArrowDownward,
  ArrowUpward,
  Delete,
  Edit,
} from '@material-ui/icons'
import { arrayMoveImmutable } from 'array-move'
import { useMemo } from 'react'

export default function CardVariants({
  actionsModalAddVariant,
  actionsModalUpdateVariant,
  actionsModalDeleteVariant,
  combos,
  attributesVariant,
  // actionsModalMagazzino,
  sortCombo,
  productId,
}) {
  const comboToUse = useMemo(() => {
    if (combos && attributesVariant) {
      return combos.map((combo) => ({
        ...combo,
        variant_values: combo.variant_values.map((variant) => ({
          ...variant,
          variant_attribute_value: attributesVariant
            .filter((d) => d.id === variant.variant_attribute)
            .pop().name,
        })),
      }))
    }
    return []
  }, [combos, attributesVariant])

  const attributesLabels = useMemo(() => {
    if (
      comboToUse &&
      comboToUse.length > 0 &&
      comboToUse[0].variant_values.length > 0
    ) {
      return comboToUse[0].variant_values.map((d) => ({
        name: d.variant_attribute_value,
      }))
    }
    return []
  }, [comboToUse])

  return (
    <Card>
      <CardHeader
        title={
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <div className="title-card-big">Combinazioni</div>
            <Add
              onClick={() => actionsModalAddVariant.open()}
              color={'primary'}
              className={'pointer'}
            />
          </div>
        }
      />
      <CardContent>
        {comboToUse ? (
          <Table size="small">
            <TableBody>
              <TableRow>
                {attributesLabels.map((attribute, i) => (
                  <TableCell className={'bg-varianti'} key={i}>
                    {attribute.name}
                  </TableCell>
                ))}
                <TableCell className={'bg-varianti'}>Prezzo</TableCell>
                <TableCell className={'bg-varianti'}>Codice</TableCell>
                <TableCell className={'bg-varianti'}></TableCell>
              </TableRow>
              {comboToUse.map((combo, i) => (
                <TableRow key={i}>
                  {combo.variant_values &&
                    combo.variant_values.map((variant, k) => (
                      <TableCell
                        className={
                          i % 2 === 0
                            ? 'bg-varianti-scuro'
                            : 'bg-varianti-chiaro'
                        }
                        key={k}
                      >
                        {variant.value}
                      </TableCell>
                    ))}
                  {/* {attributesVariant && combo && combos[0] &&  attributesVariant.length !== combos.length &&
                    [...Array(attributesVariant.length - combos[0].variant_values.length)].map(
                      (x, k) => (
                        <TableCell
                          key={k}
                          className={
                            i % 2 === 0
                              ? "bg-varianti-scuro"
                              : "bg-varianti-chiaro"
                          }
                        ></TableCell>
                      )
                    )} */}
                  <TableCell
                    className={
                      i % 2 === 0 ? 'bg-varianti-scuro' : 'bg-varianti-chiaro'
                    }
                  >
                    {combo.price}
                  </TableCell>
                  <TableCell
                    className={
                      i % 2 === 0 ? 'bg-varianti-scuro' : 'bg-varianti-chiaro'
                    }
                  >
                    {combo.code}
                  </TableCell>
                  <TableCell>
                    <div className={'icons-list'}>
                      <ArrowUpward
                        className="pointer"
                        onClick={() => {
                          sortCombo(
                            productId,
                            arrayMoveImmutable(combos, i, i - 1)
                          )
                        }}
                        fontSize="small"
                      />
                      <ArrowDownward
                        className="pointer"
                        onClick={() => {
                          sortCombo(
                            productId,
                            arrayMoveImmutable(combos, i, i + 1)
                          )
                        }}
                        fontSize="small"
                      />
                      <Edit
                        onClick={() => actionsModalUpdateVariant.open(combo)}
                        className="pointer"
                        fontSize="small"
                      />
                      {/* <Storage
                        onClick={() => actionsModalMagazzino.open(combo)}
                        className="pointer"
                        fontSize="small"
                        title={"Aggiorna quantità"}
                      /> */}
                      <Delete
                        onClick={() => actionsModalDeleteVariant.open(combo)}
                        className="pointer"
                        fontSize="small"
                      />
                    </div>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        ) : (
          <p>Non hai ancora inserito delle varianti per il prodotto.</p>
        )}
      </CardContent>
    </Card>
  )
}
