import { rj } from "react-rocketjump"
import qs from 'query-string'
import rjList, {
  nextPreviousPaginationAdapter,
} from "react-rocketjump/plugins/list"
import rjPlainList from "react-rocketjump/plugins/plainList"
import api from "../api"
import { PAGE_SIZE } from "../consts"

export const OrderState = rj(
  rjList({
    pagination: nextPreviousPaginationAdapter,
    pageSize: PAGE_SIZE,
  }),
  {
    name: "OrderState",
    effectCaller: "configured",
    mutations: {
      addOrdine: {
        effect: (t) => (entity) =>
          api.auth(t).post("/api/commerce/orders", entity),
        updater: "insertItem",
      },
      removeOrdine: {
        effect: (t) => (entity) =>
          api
            .auth(t)
            .mapResponse(() => entity)
            .delete(`/api/commerce/orders/${entity.id}`),
        updater: "deleteItem",
        // Uncomment to make delete optimistic
        // optimistiResult: entity => entity,
      },
    },
    effect:
      (t) =>
      (params = {}) =>
        api.auth(t).get(`/api/commerce/orders?${qs.stringify(params)}`),
    computed: {
      pagination: "getPagination",
      list: "getList",
      loading: "isLoading",
    },
  }
)

export const OrderDetailState = rj({
  name: "Detail",
  effectCaller: "configured",
  mutations: {
    updateOrder: {
      effect: (t) => (entity) =>
        api.auth(t).put(`/api/commerce/orders/${entity.id}/`, entity),
      updater: "updateData",
    },
  },
  effect: (t) => (id) => api.auth(t).get(`/api/commerce/orders/${id}`),
})

export const OrderRowState = rj(rjPlainList(), {
  mutations: {
    removeRow: {
      effect: (t) => (entity) =>
        api
          .auth(t)
          .mapResponse(() => entity)
          .delete(`/api/commerce/order-rows/${entity.id}`),
      updater: "deleteItem",
      // Uncomment to make delete optimistic
      // optimistiResult: entity => entity,
    },
    addRow: {
      effect: (t) => (entity) =>
        api.auth(t).post("/api/commerce/order-rows", entity),
      updater: "insertItem",
    },
    updateRow: {
      effect: (t) => (entity) =>
        api.auth(t).put(`/api/commerce/order-rows/${entity.id}/`, entity),
      updater: "updateItem",
    },
  },
  name: "OrderRowState",
  effectCaller: "configured",
  effect:
    (t) =>
    (params = {}) =>
      api.auth(t).get("/api/commerce/order-rows", params),
  computed: {
    list: "getList",
    loading: "isLoading",
  },
})
