import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'
import React from 'react'
import ReactDOM from 'react-dom'
import './styles/index.scss'
import App from './App'
import reportWebVitals from './reportWebVitals'

if (process.env.REACT_APP_SENTRY_RELEASE) {
  Sentry.init({
    dsn: 'https://915f0137056b445b9ab3a68b19e8c9c1@o1047151.ingest.sentry.io/4503930381074432',
    integrations: [new BrowserTracing()],
    release: process.env.REACT_APP_SENTRY_RELEASE,
    tracesSampleRate: 0,
  })
}

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
