import React from "react"
import * as Yup from "yup"
import { Field, Formik } from "formik"
import {
  InputField,
  setNullOnEmptyValues,
  transformErrorForForm,
} from "../Form"
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Button,
} from "@material-ui/core"
import { KeyboardBackspace } from "@material-ui/icons"
import ButtonSave from "../ButtonSave"
import { Transition } from "../../consts"

const INITIAL_VALUES = {
  stock_quantity: "",
}

const ProdottoSchema = Yup.object().shape({
  stock_quantity: Yup.string().required("Quantità è obbligatoria"),
  // value: Yup.string().required("Valore è obbligatorio"),
})

export default function ModalMagazzino({
  variant,
  save,
  isOpen,
  toggle,
  onExited,
}) {

  return (
    <Dialog open={isOpen} fullWidth onClose={toggle} TransitionComponent={Transition}>
      <DialogTitle onClose={toggle}>
        Aggiorna Quantità - {variant && variant.code}
      </DialogTitle>
      <Formik
        validationSchema={ProdottoSchema}
        initialValues={
          variant
            ? variant
            : INITIAL_VALUES
        }
        onSubmit={(values, actions) => {
          save(setNullOnEmptyValues(values)).catch((err) => {
            actions.setErrors(transformErrorForForm(err))
          })
        }}
      >
        {({ handleSubmit, isSubmitting, values, setFieldValue }) => {
          return (
            <form onSubmit={handleSubmit}>
              <DialogContent dividers>
                <Grid container spacing={3}>
                  <Grid item md={12} xs={12}>
                    <Field
                      label={"Quantità"}
                      name="stock_quantity"
                      component={InputField}
                    />
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button
                  startIcon={<KeyboardBackspace />}
                  variant="outlined"
                  onClick={toggle}
                >
                  Chiudi
                </Button>
                <ButtonSave
                  // disabled={isSubmitting}
                  type="submit"
                  variant="outlined"
                >
                  {variant ? "Aggiorna Magazzino" : "Aggiungi"}
                </ButtonSave>
              </DialogActions>
            </form>
          )
        }}
      </Formik>
    </Dialog>
  )
}
