import { rj, useRunRj } from 'react-rocketjump'
import api from '../api'

export function passwordRecoverApi(email) {
  return api
    .post('/api/commerce/recover-password/', {
      email,
    })
    .toPromise()
}

export function resetPasswordApi(token, password) {
  return api
    .post(`/api/commerce/reset-password/${token}`, {
      password,
    })
    .toPromise()
}

const CheckResetTokenState = rj({
  name: 'CheckResetToken',
  effect: (token) =>
    api.mapResponse(() => true).get(`/api/commerce/check-reset-token/${token}`),
})

export function useCheckResetToken(token) {
  return useRunRj(CheckResetTokenState, [token])
}
