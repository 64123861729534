import { rj } from "react-rocketjump"
import rjPlainList from 'react-rocketjump/plugins/plainList'
import api from "../api"

export const VideoState = rj(

  rjPlainList(),
  {
    mutations: {
      removeVideo: {
        effect: (t) => (entity) =>
          api
            .auth(t)
            .mapResponse(() => entity)
            .delete(`/api/commerce/video/${entity.id}`),
        updater: "deleteItem",
        // Uncomment to make delete optimistic
        // optimistiResult: entity => entity,
      },
      addVideo: {
        effect: (t) => (entity) => api.auth(t).post("/api/commerce/video", entity),
        updater: "insertItem",
      },
      updateVideo: {
        effect: (t) => (entity) =>
          api.auth(t).put(`/api/commerce/video/${entity.id}/`, entity),
        updater: "updateItem",
      },
    },
    name: "VideoState",
    effectCaller: "configured",
    effect: (t) => (params = {}) => api.auth(t).get("/api/commerce/video", params),
    computed: {
      list: "getList",
      loading: "isLoading",
    },
  }
)
