import React, { useMemo } from 'react'
import * as Yup from 'yup'
import { Field, Formik } from 'formik'
import {
  InputField,
  setNullOnEmptyValues,
  transformErrorForForm,
} from '../Form'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Button,
  TextField,
} from '@material-ui/core'
import { KeyboardBackspace } from '@material-ui/icons'
import ButtonSave from '../ButtonSave'
import { Autocomplete } from '@material-ui/lab'
import { Transition } from '../../consts'

const INITIAL_VALUES = {
  product_title: '',
  product: '',
  product_combo: '',
  single_price: '',
  quantity: '',
  total_price: '',
}

const OrderRowSchema = Yup.object().shape({
  single_price: Yup.number().required('Combinazione è obbligatoria'),
  quantity: Yup.number().required('Combinazione è obbligatoria'),
  total_price: Yup.number().required('Combinazione è obbligatoria'),
  product: Yup.object().nullable().required('Il cliente è obbligatorio'),
  product_combo: Yup.object()
    .nullable()
    .required('La combinazione è obbligatoria'),
})

export default function ModalFormOrderRow({
  row,
  save,
  isOpen,
  prodotti,
  toggle,
  combos,
}) {
  const prodottiToUse = useMemo(() => {
    if (prodotti && prodotti.length > 0) {
      return prodotti.map((p) => ({
        label: p.title,
        value: p.id,
      }))
    } else return []
  }, [prodotti])

  const prodottiCombosToUse = useMemo(() => {
    if (combos && combos.length > 0) {
      return combos.map((c) => ({
        label: c.code,
        value: c.id,
        ...c,
      }))
    } else return []
  }, [combos])

  return (
    <Dialog
      fullWidth={true}
      maxWidth={'sm'}
      open={isOpen}
      onClose={toggle}
      TransitionComponent={Transition}
    >
      <DialogTitle onClose={toggle}>
        {row ? 'Modifica' : 'Inserimento'} riga ordine
      </DialogTitle>
      <Formik
        validationSchema={OrderRowSchema}
        initialValues={
          row
            ? {
                ...row,
                product: row.product_combo_data.id,
                // product_combo: row.product_combo.id,
              }
            : INITIAL_VALUES
        }
        onSubmit={(values, actions) =>
          save(setNullOnEmptyValues(values)).catch((err) => {
            actions.setErrors(transformErrorForForm(err))
          })
        }
      >
        {({
          handleSubmit,
          isSubmitting,
          setFieldValue,
          values,
          errors,
          touched,
        }) => {
          return (
            <form onSubmit={handleSubmit}>
              <DialogContent dividers>
                <Grid container spacing={3}>
                  <Grid item md={12}>
                    <Autocomplete
                      onChange={(e, value) => {
                        setFieldValue('product', value)
                      }}
                      getOptionSelected={(option, value) =>
                        option.value === value.value
                      }
                      value={values.product}
                      getOptionLabel={(option) => (option ? option.label : '')}
                      id="combo-box-demo"
                      options={prodottiToUse}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          label="Prodotto"
                          error={!!errors['product']}
                          helperText={
                            !!errors['product'] || touched['product']
                              ? errors['product']
                              : undefined
                          }
                        />
                      )}
                    />
                  </Grid>
                  <Grid item md={12}>
                    <Autocomplete
                      onChange={(e, value) => {
                        if (value) {
                          setFieldValue('product_combo', value)
                          if (value.value) {
                            setFieldValue('single_price', value.price)
                          }
                        }
                      }}
                      getOptionLabel={(option) => option.label}
                      id="combo-box-demo"
                      // name="product_combo"
                      disabled={
                        values.product &&
                        values.product.value &&
                        values.product.value === ''
                      }
                      options={
                        values.product &&
                        values.product.value &&
                        values.product.value !== ''
                          ? prodottiCombosToUse.filter(
                              (d) => d.product === values.product.value
                            )
                          : []
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          label="Combinazione"
                          error={!!errors['product_combo']}
                          helperText={
                            !!errors['product_combo'] ||
                            touched['product_combo']
                              ? errors['product_combo']
                              : undefined
                          }
                        />
                      )}
                    />
                  </Grid>
                  <Grid item md={12}>
                    <Field
                      name="single_price"
                      InputLabelProps={{ shrink: true }}
                      label="Prezzo singolo"
                      component={InputField}
                    />
                  </Grid>
                  <Grid item md={12}>
                    <Field
                      name="quantity"
                      label="Quantità"
                      onChange={(e) =>
                        setFieldValue(
                          'total_price',
                          values.single_price * e.target.value
                        )
                      }
                      component={InputField}
                    />
                  </Grid>
                  <Grid item md={12}>
                    <Field
                      name="total_price"
                      disabled
                      InputLabelProps={{ shrink: true }}
                      floatingLabelText="Prezzo totale"
                      label="Prezzo totale"
                      component={InputField}
                    />
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button
                  startIcon={<KeyboardBackspace />}
                  variant="outlined"
                  onClick={toggle}
                >
                  Chiudi
                </Button>
                <ButtonSave
                  disabled={isSubmitting}
                  type="submit"
                  variant="outlined"
                >
                  {row ? 'Modifica' : 'Aggiungi'}
                </ButtonSave>
              </DialogActions>
            </form>
          )
        }}
      </Formik>
    </Dialog>
  )
}
