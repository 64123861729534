import React, { useMemo } from "react"
import useDebounceQueryParams from "magik-react-hooks/useRouterDebounceQueryParams"
import useModalTrigger from "magik-react-hooks/useModalTrigger"
import { useRunRj } from "react-rocketjump"
import Layout from "../../components/Layout/Layout"
import {
  TextField,
  Card,
  CircularProgress,
  Grid,
  InputAdornment,
  IconButton,
} from "@material-ui/core"
import { Cancel } from "@material-ui/icons"
import { isMobile } from "react-device-detect"
import ListMagazzino from "../../components/Magazzino/ListMagazzino"
import { ProductMagazzinoState } from "../../state/prodotti"
import ModalMagazzino from "../../components/Prodotti/ModalMagazzino"

export default function Magazzino() {
  const [queryParams, setQueryParams, debQueryParams, setDebQueryParams] =
    useDebounceQueryParams()

  const [modalUpdate, actionsModalUpdate] = useModalTrigger()

  const onUpdate = (combo) => actionsModalUpdate.open(combo)

  const filters = useMemo(() => {
    return { ...debQueryParams }
  }, [debQueryParams])

  const [{ list, loading, pagination }, { updateQuantity }] = useRunRj(ProductMagazzinoState, [filters], false)

  const columnProps = {
    value: queryParams.ordering,
    onChange: (value) => setDebQueryParams({ ordering: value }),
  }

  return (
    <Layout title="Magazzino">
      <div>
        <Card className="card-filters">
          <Grid container>
            <Grid item xs={12} md={10}>
              <div>
                <TextField
                  size="small"
                  placeholder="Cerca..."
                  value={queryParams.search ?? ""}
                  onChange={(e) =>
                    setDebQueryParams({
                      search: e.target.value,
                      page: 1,
                    })
                  }
                  style={{ width: isMobile ? "100%" : undefined }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          disabled={!queryParams.search}
                          aria-label="toggle password visibility"
                          onClick={() => setQueryParams(() => {})}
                          edge="end"
                        >
                          <Cancel
                            color={!queryParams.search ? undefined : "primary"}
                          />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  id="outlined-basic"
                  variant="outlined"
                />
              </div>
            </Grid>
          </Grid>
        </Card>
        {list === null && loading && (
          <div style={{ textAlign: "center" }}>
            <CircularProgress color="primary" />
          </div>
        )}
        {list && (
          <ListMagazzino
            columnProps={columnProps}
            combos={list}
            pagination={pagination}
            onUpdate={onUpdate}
          />
        )}
      </div>
      <ModalMagazzino
        save={(variant) => {
          // const payload = { ...variant, product: id }
          return updateQuantity
            .onSuccess((combo) => {
              actionsModalUpdate.close()
            })
            .asPromise(variant)
        }}
        variant={modalUpdate.value}
        isOpen={modalUpdate.isOpen}
        toggle={actionsModalUpdate.toggle}
        onExited={() => {
          actionsModalUpdate.onClosed()
        }}
      />
    </Layout>
  )
}
