import {
  Card,
  CardContent,
  CardHeader,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from "@material-ui/core"
import { STATES_ORDER } from "../../consts"
import moment from "moment"

export default function CardLogState({ logs }) {
  return (
    <Card>
      <CardHeader
        title={
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div className="title-card-big">Storia ordine</div>
          </div>
        }
      />
      <CardContent>
        {logs && logs.length > 0 ? (
          <Table size="small">
            <TableBody>
              <TableRow>
                <TableCell className={"bg-varianti"}>Data</TableCell>
                <TableCell className={"bg-varianti"}>Stato</TableCell>
              </TableRow>
              {logs.map((log, i) => (
                <TableRow key={i}>
                  <TableCell>
                    {moment(log.timestamp).format("DD/MM/YYYY HH:mm")}
                  </TableCell>
                  <TableCell>{STATES_ORDER[log.state]}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        ) : (
          <p>Non ci sono cambi di stato per l'ordine.</p>
        )}
      </CardContent>
    </Card>
  )
}
