import React from "react"
import * as Yup from "yup"
import { Field, Formik } from "formik"
import {
  InputField,
  setNullOnEmptyValues,
  transformErrorForForm,
} from "../Form"
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Button,
} from "@material-ui/core"
import { KeyboardBackspace } from "@material-ui/icons"
import ButtonSave from "../ButtonSave"
import { Transition } from "../../consts"

const INITIAL_VALUES = {
  name: "",
  value: "",
}

const ProdottoSchema = Yup.object().shape({
  name: Yup.string().required("Nome è obbligatorio"),
  value: Yup.string().required("Valore è obbligatorio"),
})

export default function ModalFormAttribute({
  attribute,
  save,
  isOpen,
  toggle,
  onExited,
}) {
  return (
    <Dialog open={isOpen} fullWidth onClose={toggle} TransitionComponent={Transition}>
      <DialogTitle onClose={toggle}>
        {attribute ? "Modifica" : "Inserimento"} attributo
      </DialogTitle>
      <Formik
        validationSchema={ProdottoSchema}
        initialValues={attribute ? attribute : INITIAL_VALUES}
        onSubmit={(values, actions) => {
          save(setNullOnEmptyValues(values)).catch((err) => {
            actions.setErrors(transformErrorForForm(err))
          })
        }}
      >
        {({ handleSubmit, isSubmitting, values, setFieldValue }) => {
          return (
            <form onSubmit={handleSubmit}>
              <DialogContent dividers>
                <Grid container spacing={3}>
                  <Grid item md={12} xs={12}>
                    <Field label={"Nome"} name="name" component={InputField} />
                  </Grid>
                  <Grid item md={12} xs={12}>
                    <Field label={"Valore"} name="value" component={InputField} />
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button
                  startIcon={<KeyboardBackspace />}
                  variant="outlined"
                  onClick={toggle}
                >
                  Chiudi
                </Button>
                <ButtonSave
                  disabled={isSubmitting}
                  type="submit"
                  variant="outlined"
                >
                  {attribute ? "Modifica" : "Aggiungi"}
                </ButtonSave>
              </DialogActions>
            </form>
          )
        }}
      </Formik>
    </Dialog>
  )
}
